// src/components/EditOrder.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  CircularProgress,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';

const EditOrder = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [foods, setFoods] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrder();
  }, [orderId]);

  const fetchOrder = async () => {
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/order_edit.php?action=fetch_order&order_id=${orderId}`);
      const orderData = response.data;
      orderData.cart_items = JSON.parse(orderData.cart_items);

      const cartItemsWithCategory = await Promise.all(
        orderData.cart_items.map(async (item) => {
          const categoryResponse = await axios.get(`https://kirupam.shop/admin/admin_api/order_edit.php?action=fetch_category&food_id=${item.foodId}`);
          item.category = categoryResponse.data.title;
          return item;
        })
      );

      orderData.cart_items = cartItemsWithCategory;
      setOrder(orderData);
      await fetchFoods(orderData.store_id);
    } catch (error) {
      toast.error('Failed to fetch order details');
    } finally {
      setLoading(false);
    }
  };

  const fetchFoods = async (storeId) => {
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/order_edit.php?action=fetch_foods&store_id=${storeId}`);
      const foodsWithDetails = await Promise.all(
        response.data.map(async (food) => {
          const optionsResponse = await axios.get(`https://kirupam.shop/admin/admin_api/order_edit.php?action=fetch_options&food_id=${food.id}`);
          const sizesResponse = await axios.get(`https://kirupam.shop/admin/admin_api/order_edit.php?action=fetch_sizes&food_id=${food.id}`);
          return {
            ...food,
            customOptions: optionsResponse.data,
            sizes: sizesResponse.data,
          };
        })
      );
      setFoods(foodsWithDetails);
    } catch (error) {
      toast.error('Failed to fetch foods');
    }
  };

  const handleAddToOrder = (food) => {
    const selectedSize = food.sizes.find((size) => size.id === food.selectedSize);
    const selectedOptions = food.selectedOptions || [];
    const optionsTotalPrice = selectedOptions.reduce((sum, option) => sum + option.option_price, 0);
    const totalPrice = food.base_price + selectedSize.size_price + optionsTotalPrice;

    const newItem = {
      food: food.title,
      category: food.category,
      selectedSizes: [{ size: selectedSize.size, quantity: 1 }],
      selectedCustomOptions: { [selectedSize.size]: selectedOptions.map((option) => option.custom_option) },
      totalPrice,
    };

    setOrder((prevOrder) => ({
      ...prevOrder,
      cart_items: [...prevOrder.cart_items, newItem],
      totalCharge: prevOrder.totalCharge + totalPrice,
    }));

    toast.success('Item added to order');
  };

  const handleQuantityChange = (index, quantity) => {
    const newCartItems = [...order.cart_items];
    newCartItems[index].selectedSizes[0].quantity = quantity;
    setOrder({ ...order, cart_items: newCartItems });
  };

  const handleSaveOrder = async () => {
    setLoading(true);
    try {
      const updatedOrder = {
        ...order,
        cart_items: JSON.stringify(order.cart_items),
      };
      await axios.post('https://kirupam.shop/admin/admin_api/ordermanagement.php?action=update_order', updatedOrder);
      toast.success('Order updated successfully!');
    } catch (error) {
      toast.error('Failed to update order');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Typography variant="h4" gutterBottom>Edit Order</Typography>
      {order && (
        <>
          <Box sx={{ mb: 3, padding: 2, backgroundColor: '#fff', borderRadius: 1, boxShadow: 2 }}>
            <Typography variant="h5">Order Items</Typography>
            {order.cart_items.map((item, index) => (
              <Box key={index} sx={{ borderBottom: '1px solid #e0e0e0', padding: 1 }}>
                <Typography><strong>Food:</strong> {item.food}</Typography>
                <Typography><strong>Category:</strong> {item.category}</Typography>
                {item.selectedSizes.map((size, sizeIndex) => (
                  <Box key={sizeIndex}>
                    <Typography><strong>Size:</strong> {size.size} | <strong>Quantity:</strong>
                      <TextField
                        type="number"
                        value={size.quantity}
                        onChange={(e) => handleQuantityChange(index, e.target.value)}
                        sx={{ width: 100, ml: 1 }}
                      />
                    </Typography>
                    {item.selectedCustomOptions && item.selectedCustomOptions[size.size] && item.selectedCustomOptions[size.size].length > 0 && (
                      <Typography><strong>Custom Options:</strong> {item.selectedCustomOptions[size.size].join(', ')}</Typography>
                    )}
                  </Box>
                ))}
                <Typography><strong>Total Price:</strong> {item.totalPrice}</Typography>
              </Box>
            ))}
            <Typography variant="h6" sx={{ mt: 2 }}>Total Charge: {order.totalCharge}</Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={handleSaveOrder}>
            Save Order
          </Button>
          <Typography variant="h5" sx={{ mt: 4 }}>Add New Food Items</Typography>
          {foods.length ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Food</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Base Price</TableCell>
                    <TableCell>Sizes</TableCell>
                    <TableCell>Custom Options</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foods.map((food) => (
                    <TableRow key={food.id}>
                      <TableCell>{food.id}</TableCell>
                      <TableCell>{food.title}</TableCell>
                      <TableCell>{food.category}</TableCell>
                      <TableCell>{food.base_price}</TableCell>
                      <TableCell>
                        {food.sizes.map((size) => (
                          <Chip
                            key={size.id}
                            label={`${size.size} - ${size.size_price}`}
                            onClick={() => {
                              const updatedFood = { ...food, selectedSize: size.id };
                              setFoods((prevFoods) =>
                                prevFoods.map((f) => (f.id === food.id ? updatedFood : f))
                              );
                            }}
                            sx={{ margin: 0.5, cursor: 'pointer' }}
                            color={food.selectedSize === size.id ? 'primary' : 'default'}
                          />
                        ))}
                      </TableCell>
                      <TableCell>
                        {food.customOptions.map((option) => (
                          <Chip
                            key={option.id}
                            label={`${option.custom_option} - ${option.option_price}`}
                            onClick={() => {
                              const updatedFood = {
                                ...food,
                                selectedOptions: food.selectedOptions
                                  ? food.selectedOptions.includes(option)
                                    ? food.selectedOptions.filter((opt) => opt.id !== option.id)
                                    : [...food.selectedOptions, option]
                                  : [option],
                              };
                              setFoods((prevFoods) =>
                                prevFoods.map((f) => (f.id === food.id ? updatedFood : f))
                              );
                            }}
                            sx={{ margin: 0.5, cursor: 'pointer' }}
                            color={food.selectedOptions && food.selectedOptions.includes(option) ? 'primary' : 'default'}
                          />
                        ))}
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handleAddToOrder(food)}>
                          Add to Order
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No foods found.</Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default EditOrder;
