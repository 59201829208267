import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Tabs, Tab, TablePagination, IconButton, InputLabel, Select, MenuItem, FormControl
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const UserManagement = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [users, setUsers] = useState([]);
  const [riders, setRiders] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [newUser, setNewUser] = useState({ name: '', email: '', phone: '' });
  const [newRider, setNewRider] = useState({ name: '', email: '', phone: '', vehicle_number: '' });
  const [newWallet, setNewWallet] = useState({ user_id: '', balance: '', status: '' });
  const [newAdmin, setNewAdmin] = useState({ full_name: '', username: '', email: '', password: '', phone: '', area: [] });
  const [currentItem, setCurrentItem] = useState(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const districts = [
    'Ampara', 'Anuradhapura', 'Badulla', 'Batticaloa', 'Colombo',
    'Galle', 'Gampaha', 'Hambantota', 'Jaffna', 'Kalutara',
    'Kandy', 'Kegalle', 'Kilinochchi', 'Kurunegala', 'Mannar',
    'Matale', 'Matara', 'Monaragala', 'Mullaitivu', 'Nuwara Eliya',
    'Polonnaruwa', 'Puttalam', 'Ratnapura', 'Trincomalee', 'Vavuniya'
  ];

  useEffect(() => {
    fetchData();
  }, [tabIndex]);

  const fetchData = async () => {
    fetchUsers();
    if (tabIndex === 1) {
      fetchRiders();
    } else if (tabIndex === 2) {
      fetchWallets();
    } else if (tabIndex === 3) {
      fetchAdmins();
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/user_managment.php?action=fetch');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchRiders = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/user_managment.php?action=fetch_riders');
      setRiders(response.data);
    } catch (error) {
      console.error('Error fetching riders:', error);
    }
  };

  const fetchWallets = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/user_managment.php?action=fetch_wallets');
      setWallets(response.data);
    } catch (error) {
      console.error('Error fetching wallets:', error);
    }
  };

  const fetchAdmins = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/user_managment.php?action=fetch_admins');
      setAdmins(response.data);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClickOpen = (item = null) => {
    setCurrentItem(item);
    if (item) {
      if (tabIndex === 0) {
        setNewUser({ name: item.name, email: item.email, phone: item.phone });
      } else if (tabIndex === 1) {
        setNewRider({ name: item.name, email: item.email, phone: item.phone, vehicle_number: item.vehicle_number });
      } else if (tabIndex === 2) {
        setNewWallet({ user_id: item.user_id, balance: item.balance, status: item.status });
      } else if (tabIndex === 3) {
        setNewAdmin({ full_name: item.full_name, username: item.username, email: item.email, password: '', phone: item.phone, area: item.area.split(',') });
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewUser({ name: '', email: '', phone: '' });
    setNewRider({ name: '', email: '', phone: '', vehicle_number: '' });
    setNewWallet({ user_id: '', balance: '', status: '' });
    setNewAdmin({ full_name: '', username: '', email: '', password: '', phone: '', area: [] });
    setCurrentItem(null);
  };

  const handleChange = (e) => {
    if (tabIndex === 0) {
      setNewUser({ ...newUser, [e.target.name]: e.target.value });
    } else if (tabIndex === 1) {
      setNewRider({ ...newRider, [e.target.name]: e.target.value });
    } else if (tabIndex === 2) {
      setNewWallet({ ...newWallet, [e.target.name]: e.target.value });
    } else if (tabIndex === 3) {
      setNewAdmin({ ...newAdmin, [e.target.name]: e.target.value });
    }
  };

  const handleAddOrEdit = async () => {
    try {
      if (currentItem) {
        if (tabIndex === 0) {
          await axios.post(`https://kirupam.shop/admin/admin_api/user_managment.php?action=edit`, { ...newUser, id: currentItem.id });
        } else if (tabIndex === 1) {
          await axios.post(`https://kirupam.shop/admin/admin_api/user_managment.php?action=edit_rider`, { ...newRider, id: currentItem.id });
        } else if (tabIndex === 2) {
          await axios.post(`https://kirupam.shop/admin/admin_api/user_managment.php?action=edit_wallet`, { ...newWallet, id: currentItem.id });
        } else if (tabIndex === 3) {
          await axios.post(`https://kirupam.shop/admin/admin_api/user_managment.php?action=edit_admin`, { ...newAdmin, id: currentItem.id });
        }
      } else {
        if (tabIndex === 0) {
          await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=add', newUser);
        } else if (tabIndex === 1) {
          await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=add_rider', newRider);
        } else if (tabIndex === 2) {
          await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=add_wallet', newWallet);
        } else if (tabIndex === 3) {
          await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=add_admin', newAdmin);
        }
      }
      fetchData();
      handleClose();
    } catch (error) {
      console.error('Error adding/editing:', error);
    }
  };

  const handleDelete = async () => {
    try {
      if (tabIndex === 0) {
        await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=delete', { id: currentItem.id });
      } else if (tabIndex === 1) {
        await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=delete_rider', { id: currentItem.id });
      } else if (tabIndex === 2) {
        await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=delete_wallet', { id: currentItem.id });
      } else if (tabIndex === 3) {
        await axios.post('https://kirupam.shop/admin/admin_api/user_managment.php?action=delete_admin', { id: currentItem.id });
      }
      fetchData();
      setDeleteOpen(false);
      setCurrentItem(null);
    } catch (error) {
      console.error('Error deleting:', error);
    }
  };

  const handleDeleteOpen = (item) => {
    setCurrentItem(item);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setCurrentItem(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUserById = (userId) => {
    const user = users.find(user => user.id === userId);
    return user ? user.name : 'Unknown User';
  };

  const filteredUsers = users.filter(user => user.name.toLowerCase().includes(search.toLowerCase()));
  const filteredRiders = riders.filter(rider => rider.name.toLowerCase().includes(search.toLowerCase()));
  const filteredWallets = wallets.filter(wallet => getUserById(wallet.user_id).toLowerCase().includes(search.toLowerCase()));
  const filteredAdmins = admins.filter(admin => admin.full_name.toLowerCase().includes(search.toLowerCase()));

  return (
    <div>
      <h1>User Management</h1>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Users" />
        <Tab label="Riders" />
        <Tab label="Wallets" />
        <Tab label="Admins" />
      </Tabs>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={handleSearchChange}
      />
      <Button variant="outlined" color="primary" onClick={() => handleClickOpen()}>Add {tabIndex === 0 ? "User" : tabIndex === 1 ? "Rider" : tabIndex === 2 ? "Wallet" : "Admin"}</Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {tabIndex === 0 && <>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Actions</TableCell>
              </>}
              {tabIndex === 1 && <>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Vehicle Number</TableCell>
                <TableCell>Actions</TableCell>
              </>}
              {tabIndex === 2 && <>
                <TableCell>ID</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </>}
              {tabIndex === 3 && <>
                <TableCell>ID</TableCell>
                <TableCell>Full Name</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Area</TableCell>
                <TableCell>Actions</TableCell>
              </>}
            </TableRow>
          </TableHead>
          <TableBody>
            {tabIndex === 0 && filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleClickOpen(user)} color="primary"><EditIcon /></IconButton>
                  <IconButton onClick={() => handleDeleteOpen(user)} color="secondary"><DeleteIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
            {tabIndex === 1 && filteredRiders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(rider => (
              <TableRow key={rider.id}>
                <TableCell>{rider.id}</TableCell>
                <TableCell>{rider.name}</TableCell>
                <TableCell>{rider.email}</TableCell>
                <TableCell>{rider.phone}</TableCell>
                <TableCell>{rider.vehicle_number}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleClickOpen(rider)} color="primary"><EditIcon /></IconButton>
                  <IconButton onClick={() => handleDeleteOpen(rider)} color="secondary"><DeleteIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
            {tabIndex === 2 && filteredWallets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(wallet => (
              <TableRow key={wallet.id}>
                <TableCell>{wallet.id}</TableCell>
                <TableCell>{getUserById(wallet.user_id)}</TableCell>
                <TableCell>{wallet.balance}</TableCell>
                <TableCell>{wallet.status}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleClickOpen(wallet)} color="primary"><EditIcon /></IconButton>
                  <IconButton onClick={() => handleDeleteOpen(wallet)} color="secondary"><DeleteIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
            {tabIndex === 3 && filteredAdmins.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(admin => (
              <TableRow key={admin.id}>
                <TableCell>{admin.id}</TableCell>
                <TableCell>{admin.full_name}</TableCell>
                <TableCell>{admin.username}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>{admin.phone}</TableCell>
                <TableCell>{admin.area}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleClickOpen(admin)} color="primary"><EditIcon /></IconButton>
                  <IconButton onClick={() => handleDeleteOpen(admin)} color="secondary"><DeleteIcon /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={
          tabIndex === 0 ? filteredUsers.length
          : tabIndex === 1 ? filteredRiders.length
          : tabIndex === 2 ? filteredWallets.length
          : filteredAdmins.length
        }
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{currentItem ? `Edit ${tabIndex === 0 ? "User" : tabIndex === 1 ? "Rider" : tabIndex === 2 ? "Wallet" : "Admin"}` : `Add New ${tabIndex === 0 ? "User" : tabIndex === 1 ? "Rider" : tabIndex === 2 ? "Wallet" : "Admin"}`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {currentItem ? `To edit the ${tabIndex === 0 ? "user" : tabIndex === 1 ? "rider" : tabIndex === 2 ? "wallet" : "admin"}, please modify the fields below.` : `To add a new ${tabIndex === 0 ? "user" : tabIndex === 1 ? "rider" : tabIndex === 2 ? "wallet" : "admin"}, please enter the details here.`}
          </DialogContentText>
          {tabIndex === 0 && <>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={newUser.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={newUser.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="phone"
              label="Phone"
              type="text"
              fullWidth
              value={newUser.phone}
              onChange={handleChange}
            />
          </>}
          {tabIndex === 1 && <>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={newRider.name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={newRider.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="phone"
              label="Phone"
              type="text"
              fullWidth
              value={newRider.phone}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="vehicle_number"
              label="Vehicle Number"
              type="text"
              fullWidth
              value={newRider.vehicle_number}
              onChange={handleChange}
            />
          </>}
          {tabIndex === 2 && <>
            <TextField
              autoFocus
              margin="dense"
              name="user_id"
              label="User ID"
              type="text"
              fullWidth
              value={newWallet.user_id}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="balance"
              label="Balance"
              type="text"
              fullWidth
              value={newWallet.balance}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="status"
              label="Status"
              type="text"
              fullWidth
              value={newWallet.status}
              onChange={handleChange}
            />
          </>}
          {tabIndex === 3 && <>
            <TextField
              autoFocus
              margin="dense"
              name="full_name"
              label="Full Name"
              type="text"
              fullWidth
              value={newAdmin.full_name}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="username"
              label="Username"
              type="text"
              fullWidth
              value={newAdmin.username}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              value={newAdmin.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="password"
              label="Password"
              type="password"
              fullWidth
              value={newAdmin.password}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              name="phone"
              label="Phone"
              type="text"
              fullWidth
              value={newAdmin.phone}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="dense">
              <InputLabel>Area</InputLabel>
              <Select
                multiple
                name="area"
                value={newAdmin.area}
                onChange={(e) => setNewAdmin({ ...newAdmin, area: e.target.value })}
                renderValue={(selected) => selected.join(', ')}
              >
                <MenuItem value="all">All</MenuItem>
                {districts.map((district) => (
                  <MenuItem key={district} value={district}>
                    {district}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleAddOrEdit} color="primary">{currentItem ? "Edit" : "Add"}</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteOpen} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this {tabIndex === 0 ? "user" : tabIndex === 1 ? "rider" : tabIndex === 2 ? "wallet" : "admin"}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">Cancel</Button>
          <Button onClick={handleDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserManagement;
