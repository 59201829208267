import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress, MenuItem,
  InputAdornment, Pagination
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DeliveryRiderManagement = () => {
  const [riders, setRiders] = useState([]);
  const [selectedRider, setSelectedRider] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);
  const [earnings, setEarnings] = useState([]);
  const [addRiderDialogOpen, setAddRiderDialogOpen] = useState(false);
  const [newRider, setNewRider] = useState({
    name: '', email: '', phone: '', address: '', vehicle_number: '', nic_number: '', password: '',
    vehicle_photo: null, profile_image: null, licence_photo: null
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchRiders();
  }, [page]);

  const fetchRiders = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/rider_management.php?action=fetch_riders&page=${page}&search=${search}`);
      setRiders(response.data.riders);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching riders:', error);
      setLoading(false);
    }
  };

  const fetchRiderDetails = async (riderId, startDate = '', endDate = '') => {
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/rider_management.php?action=fetch_rider_details&rider_id=${riderId}&start_date=${startDate}&end_date=${endDate}`);
      setSelectedRider(response.data.rider);
      setFeedback(response.data.feedback);
      setWithdrawals(response.data.withdrawals);
      setEarnings(response.data.earnings);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error fetching rider details:', error);
    }
  };

  const handleApprove = async (riderId, status) => {
    try {
      await axios.post('https://kirupam.shop/admin/admin_api/rider_management.php?action=approve_rider', { rider_id: riderId, status });
      fetchRiders();
      toast.success('Rider status updated successfully!');
    } catch (error) {
      toast.error('Error updating rider status');
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedRider(null);
    setFeedback([]);
    setWithdrawals([]);
    setEarnings([]);
  };

  const handleAddRider = async () => {
    const formData = new FormData();
    Object.keys(newRider).forEach(key => {
      formData.append(key, newRider[key]);
    });

    try {
      await axios.post('https://kirupam.shop/admin/admin_api/rider_management.php?action=add_rider', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      fetchRiders();
      setAddRiderDialogOpen(false);
      setNewRider({ name: '', email: '', phone: '', address: '', vehicle_number: '', nic_number: '', password: '', vehicle_photo: null, profile_image: null, licence_photo: null });
      toast.success('Rider added successfully!');
    } catch (error) {
      toast.error('Error adding rider');
    }
  };

  const handleDateFilter = () => {
    if (selectedRider) {
      fetchRiderDetails(selectedRider.id, startDate, endDate);
    }
  };

  const handleFileChange = (e) => {
    setNewRider({ ...newRider, [e.target.name]: e.target.files[0] });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    fetchRiders();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 3 }}>
      <ToastContainer />
      <form onSubmit={handleSearchSubmit} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
        <TextField
          label="Search Riders"
          variant="outlined"
          value={search}
          onChange={handleSearchChange}
          fullWidth
          sx={{ marginRight: '16px' }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button type="submit" variant="contained" color="primary">Search</Button>
      </form>
      <Button variant="contained" color="primary" onClick={() => setAddRiderDialogOpen(true)} sx={{ mb: 2 }}>
        Add New Rider
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Approve Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {riders.map((rider) => (
              <TableRow key={rider.id}>
                <TableCell>{rider.id}</TableCell>
                <TableCell>{rider.name}</TableCell>
                <TableCell>{rider.email}</TableCell>
                <TableCell>{rider.phone}</TableCell>
                <TableCell>{rider.approve_status ? 'Approved' : 'Pending'}</TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => fetchRiderDetails(rider.id)}>
                    View Details
                  </Button>
                  <Button variant="contained" color="success" onClick={() => handleApprove(rider.id, 1)} disabled={rider.approve_status}>
                    Approve
                  </Button>
                  <Button variant="contained" color="error" onClick={() => handleApprove(rider.id, 0)} disabled={!rider.approve_status}>
                    Reject
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination count={totalPages} page={page} onChange={handlePageChange} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }} />
      </TableContainer>

      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Rider Details</DialogTitle>
        <DialogContent>
          {selectedRider && (
            <>
              <h3>Personal Information</h3>
              <TextField label="Name" fullWidth margin="normal" value={selectedRider.name} />
              <TextField label="Email" fullWidth margin="normal" value={selectedRider.email} />
              <TextField label="Phone" fullWidth margin="normal" value={selectedRider.phone} />
              <TextField label="Address" fullWidth margin="normal" value={selectedRider.address} />
              <TextField label="Vehicle Number" fullWidth margin="normal" value={selectedRider.vehicle_number} />
              <TextField label="NIC Number" fullWidth margin="normal" value={selectedRider.nic_number} />

              <h3>Vehicle Photo</h3>
              {selectedRider.vehicle_photo && (
                <Box component="img" src={`https://kirupam.shop/admin/${selectedRider.vehicle_photo}`} alt="Vehicle" sx={{ width: '100%', mb: 2 }} />
              )}

              <h3>Profile Image</h3>
              {selectedRider.profile_image && (
                <Box component="img" src={`https://kirupam.shop/admin/${selectedRider.profile_image}`} alt="Profile" sx={{ width: '100%', mb: 2 }} />
              )}

              <h3>Licence Photo</h3>
              {selectedRider.licence_photo && (
                <Box component="img" src={`https://kirupam.shop/admin/${selectedRider.licence_photo}`} alt="Licence" sx={{ width: '100%', mb: 2 }} />
              )}

              <h3>Filter Earnings by Date Range</h3>
              <Box display="flex" gap={2} mb={3}>
                <TextField
                  label="Start Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <TextField
                  label="End Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleDateFilter}>Filter</Button>
              </Box>

              <h3>Feedback</h3>
              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Rating</TableCell>
                      <TableCell>Feedback</TableCell>
                      <TableCell>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feedback.map((fb) => (
                      <TableRow key={fb.id}>
                        <TableCell>{fb.rating}</TableCell>
                        <TableCell>{fb.feedback}</TableCell>
                        <TableCell>{fb.created_at}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <h3>Withdrawals</h3>
              <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Amount</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {withdrawals.map((wd) => (
                      <TableRow key={wd.id}>
                        <TableCell>{wd.amount}</TableCell>
                        <TableCell>{wd.date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <h3>Earnings</h3>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Earnings</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {earnings.map((earning) => (
                      <TableRow key={earning.date}>
                        <TableCell>{earning.date}</TableCell>
                        <TableCell>{earning.earnings}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addRiderDialogOpen} onClose={() => setAddRiderDialogOpen(false)}>
        <DialogTitle>Add New Rider</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={newRider.name}
            onChange={(e) => setNewRider({ ...newRider, name: e.target.value })}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={newRider.email}
            onChange={(e) => setNewRider({ ...newRider, email: e.target.value })}
          />
          <TextField
            label="Phone"
            fullWidth
            margin="normal"
            value={newRider.phone}
            onChange={(e) => setNewRider({ ...newRider, phone: e.target.value })}
          />
          <TextField
            label="Address"
            fullWidth
            margin="normal"
            value={newRider.address}
            onChange={(e) => setNewRider({ ...newRider, address: e.target.value })}
          />
          <TextField
            label="Vehicle Number"
            fullWidth
            margin="normal"
            value={newRider.vehicle_number}
            onChange={(e) => setNewRider({ ...newRider, vehicle_number: e.target.value })}
          />
          <TextField
            label="NIC Number"
            fullWidth
            margin="normal"
            value={newRider.nic_number}
            onChange={(e) => setNewRider({ ...newRider, nic_number: e.target.value })}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={newRider.password}
            onChange={(e) => setNewRider({ ...newRider, password: e.target.value })}
          />
          <label>Vehicle Photo</label>
          <input
            type="file"
            name="vehicle_photo"
            onChange={handleFileChange}
            style={{ margin: '16px 0' }}
          />
          <label>Profile Image</label>
          <input
            type="file"
            name="profile_image"
            onChange={handleFileChange}
            style={{ margin: '16px 0' }}
          />
          <label>Licence Photo</label>
          <input
            type="file"
            name="licence_photo"
            onChange={handleFileChange}
            style={{ margin: '16px 0' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddRiderDialogOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleAddRider} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeliveryRiderManagement;
