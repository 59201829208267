import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const NotificationSound = () => {
  const [lastChecked, setLastChecked] = useState(Date.now());
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkForNewOrders();
    }, 10000); // Check every 10 seconds

    return () => clearInterval(interval);
  }, [lastChecked]);

  const checkForNewOrders = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/check_new_orders.php');
      const newOrders = response.data.newOrders;

      if (newOrders.length > 0) {
        console.log('New orders detected:', newOrders);
        setOrders([...orders, ...newOrders]);
        playNotificationSound();
        newOrders.forEach(order => showToastNotification(order)); // Show notification for each new order
        setLastChecked(Date.now());
      }
    } catch (error) {
      console.error('Error checking for new orders:', error);
    }
  };

  const playNotificationSound = () => {
    const audio = new Audio('/success.mp3');
    audio.play()
      .then(() => {
        console.log('Notification sound played successfully.');
      })
      .catch(error => {
        console.error('Error playing notification sound:', error);
      });
  };

  const showToastNotification = (order) => {
    toast.info(`New Order Placed!\nOrder ID: ${order.id}\nTotal: $${order.totalCharge}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default NotificationSound;
