import React, { useState, useEffect } from 'react';
import {
  Box, Grid, Typography, Paper, CircularProgress, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';
import axios from 'axios';
import moment from 'moment';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Dashboard = () => {
  const [allOrdersData, setAllOrdersData] = useState([]);
  const [filteredOrdersData, setFilteredOrdersData] = useState([]);
  const [allTasksData, setAllTasksData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState('');
  const [stats, setStats] = useState({
    totalOrders: 0,
    pendingOrders: 0,
    pendingRevenue: 0,
    completedOrders: 0,
    completedRevenue: 0,
    cancelledOrders: 0,
    totalRevenue: 0,
    totalSales: 0,
    totalDeliveryCharges: 0,
    totalOfTotalCharges: 0,
    completedTasks: 0,
    pendingTasks: 0,
  });
  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState({
    title: '',
    description: '',
    details: ''
  });

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (date) {
      filterData();
    }
  }, [date, allOrdersData, allTasksData]);

  const fetchAllData = async () => {
    setLoading(true);
    try {
      const [ordersResponse, tasksResponse] = await Promise.all([
        axios.get('https://kirupam.shop/admin/admin_api/orders_sat.php'),
        axios.get('https://kirupam.shop/admin/admin_api/tasks.php'),
      ]);
      setAllOrdersData(Array.isArray(ordersResponse.data) ? ordersResponse.data : []);
      setAllTasksData(Array.isArray(tasksResponse.data) ? tasksResponse.data : []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const filterData = () => {
    const selectedDate = moment(date).format('YYYY-MM-DD');
    const filteredOrders = allOrdersData.filter(order => moment(order.selected_date).format('YYYY-MM-DD') === selectedDate);
    const filteredTasks = allTasksData.filter(task => moment(task.created_at).format('YYYY-MM-DD') === selectedDate);

    const totalOrders = filteredOrders.length;
    const pendingOrders = filteredOrders.filter(order => order.order_status === 'Pending').length;
    const completedOrders = filteredOrders.filter(order => order.order_status === 'Completed').length;
    const cancelledOrders = filteredOrders.filter(order => order.order_status === 'Cancelled').length;

    const pendingRevenue = filteredOrders.reduce((acc, order) => {
      const totalCharge = parseFloat(order.totalCharge) || 0;
      const deliveryCharge = parseFloat(order.delivery_charge) || 0;
      return acc + (order.order_status === 'Pending' ? totalCharge + deliveryCharge : 0);
    }, 0);

    const completedRevenue = filteredOrders.reduce((acc, order) => {
      const totalCharge = parseFloat(order.totalCharge) || 0;
      const deliveryCharge = parseFloat(order.delivery_charge) || 0;
      return acc + (order.order_status === 'Completed' ? totalCharge + deliveryCharge : 0);
    }, 0);

    const totalRevenue = pendingRevenue + completedRevenue;

    const totalSales = filteredOrders.reduce((acc, order) => acc + (parseFloat(order.totalCharge) || 0), 0);
    const totalDeliveryCharges = filteredOrders.reduce((acc, order) => acc + (parseFloat(order.delivery_charge) || 0), 0);
    const totalOfTotalCharges = totalSales + totalDeliveryCharges;

    const completedTasks = filteredTasks.filter(task => task.task_status === 'completed').length;
    const pendingTasks = filteredTasks.filter(task => task.task_status === 'pending').length;

    setStats({ totalOrders, pendingOrders, pendingRevenue, completedOrders, completedRevenue, cancelledOrders, totalRevenue, totalSales, totalDeliveryCharges, totalOfTotalCharges, completedTasks, pendingTasks });
    setFilteredOrdersData(processOrdersData(filteredOrders, selectedDate));
  };

  const processOrdersData = (orders, selectedDate) => {
    const data = { date: selectedDate, Pending: 0, Completed: 0, Cancelled: 0 };

    orders.forEach(order => {
      if (order.order_status === 'Pending') data.Pending++;
      if (order.order_status === 'Completed') data.Completed++;
      if (order.order_status === 'Cancelled') data.Cancelled++;
    });

    return [data];
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNotification({ ...notification, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('title', notification.title);
      formData.append('description', notification.description);
      formData.append('details', notification.details);

      const response = await axios.post('https://kirupam.shop/admin/admin_api/add_notification.php', formData);
      if (response.data.success) {
        toast.success('Notification added successfully');
        handleClose();
      } else {
        toast.error('Failed to add notification');
      }
    } catch (error) {
      toast.error('Error adding notification');
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'LKR' }).format(amount);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <ToastContainer />
      <Typography variant="h2" gutterBottom>Dashboard</Typography>
      <Box mb={4} display="flex" alignItems="center">
        <TextField
          label="Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={filterData} sx={{ ml: 2 }}>Apply</Button>
        <Button variant="contained" color="secondary" onClick={handleClickOpen} sx={{ ml: 2 }}>Add Notification</Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#e3f2fd' }}>
            <Typography variant="h5">Total Orders</Typography>
            <Typography variant="h4">{stats.totalOrders}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#fff3e0' }}>
            <Typography variant="h5">Pending Orders</Typography>
            <Typography variant="h4">{stats.pendingOrders}</Typography>
            <Typography variant="subtitle1">Revenue: {formatCurrency(stats.pendingRevenue)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#e8f5e9' }}>
            <Typography variant="h5">Completed Orders</Typography>
            <Typography variant="h4">{stats.completedOrders}</Typography>
            <Typography variant="subtitle1">Revenue: {formatCurrency(stats.completedRevenue)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#ffebee' }}>
            <Typography variant="h5">Cancelled Orders</Typography>
            <Typography variant="h4">{stats.cancelledOrders}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#f3e5f5' }}>
            <Typography variant="h5">Total Revenue</Typography>
            <Typography variant="h4">{formatCurrency(stats.totalRevenue)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#f3e5f5' }}>
            <Typography variant="h5">Total Sales</Typography>
            <Typography variant="h4">{formatCurrency(stats.totalSales)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#f3e5f5' }}>
            <Typography variant="h5">Total Delivery Charges</Typography>
            <Typography variant="h4">{formatCurrency(stats.totalDeliveryCharges)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#f3e5f5' }}>
            <Typography variant="h5">Total of Total Charges</Typography>
            <Typography variant="h4">{formatCurrency(stats.totalOfTotalCharges)}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Order Status Breakdown</Typography>
            <PieChart width={400} height={400}>
              <Pie
                data={filteredOrdersData}
                dataKey="Pending"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {filteredOrdersData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Revenue Breakdown</Typography>
            <PieChart width={400} height={400}>
              <Pie
                data={[
                  { name: 'Pending Revenue', value: stats.pendingRevenue },
                  { name: 'Completed Revenue', value: stats.completedRevenue },
                  { name: 'Cancelled Revenue', value: 0 }
                ]}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {[
                  { name: 'Pending Revenue', value: stats.pendingRevenue },
                  { name: 'Completed Revenue', value: stats.completedRevenue },
                  { name: 'Cancelled Revenue', value: 0 }
                ].map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Task Analysis</Typography>
            <PieChart width={400} height={400}>
              <Pie
                data={[
                  { name: 'Pending Tasks', value: stats.pendingTasks },
                  { name: 'Completed Tasks', value: stats.completedTasks }
                ]}
                dataKey="value"
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              >
                {[
                  { name: 'Pending Tasks', value: stats.pendingTasks },
                  { name: 'Completed Tasks', value: stats.completedTasks }
                ].map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h5">Orders Over Time</Typography>
            <BarChart
              width={500}
              height={300}
              data={filteredOrdersData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Pending" fill="#ffbb33" />
              <Bar dataKey="Completed" fill="#00C49F" />
              <Bar dataKey="Cancelled" fill="#FF4444" />
            </BarChart>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Notification</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            value={notification.title}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            type="text"
            fullWidth
            value={notification.description}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="details"
            label="Details"
            type="text"
            fullWidth
            value={notification.details}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
