import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, FormControl, InputLabel, Select, MenuItem, InputAdornment, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Menu = () => {
  const [stores, setStores] = useState([]);
  const [foods, setFoods] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedFood, setSelectedFood] = useState(null);
  const [foodDetails, setFoodDetails] = useState(null);
  const [searchStore, setSearchStore] = useState('');
  const [searchFood, setSearchFood] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [foodToDelete, setFoodToDelete] = useState(null);
  const [addFoodDialogOpen, setAddFoodDialogOpen] = useState(false);
  const [newFoodDetails, setNewFoodDetails] = useState({
    title: '',
    base_price: '',
    quantity: '',
    des: '',
    category_id: '',
    stock: '',
    foodtime: '',
    sizes: [],
    options: [],
    featured_image: '',
    gallery_images: [],
    store_id: ''
  });

  // Pagination state
  const [storePage, setStorePage] = useState(0);
  const [storeRowsPerPage, setStoreRowsPerPage] = useState(5);
  const [foodPage, setFoodPage] = useState(0);
  const [foodRowsPerPage, setFoodRowsPerPage] = useState(5);

  useEffect(() => {
    fetchStores();
    fetchCategories();
  }, []);

  const fetchStores = async () => {
    setLoading(true);
    const response = await axios.get('https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_stores');
    setStores(response.data);
    setLoading(false);
  };

  const fetchCategories = async () => {
    const response = await axios.get('https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_categories');
    setCategories(response.data);
  };

  const handleStoreSelect = async (store) => {
    setLoading(true);
    setSelectedStore(store);
    const response = await axios.get(`https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_foods&store_id=${store.id}`);
    setFoods(response.data);
    setSelectedFood(null);
    setFoodDetails(null);
    setLoading(false);
  };

  const handleFoodSelect = async (food) => {
    setLoading(true);
    setSelectedFood(food);
    const response = await axios.get(`https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_food_details&food_id=${food.id}`);
    setFoodDetails(response.data);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFoodDetails({ ...foodDetails, [name]: value });
  };

  const handleSizeChange = (index, e) => {
    const { name, value } = e.target;
    const sizes = [...foodDetails.sizes];
    sizes[index][name] = value;
    setFoodDetails({ ...foodDetails, sizes });
  };

  const handleOptionChange = (index, e) => {
    const { name, value } = e.target;
    const options = [...foodDetails.options];
    options[index][name] = value;
    setFoodDetails({ ...foodDetails, options });
  };

  const handleAddSize = () => {
    const sizes = [...foodDetails.sizes, { size: '', size_price: '' }];
    setFoodDetails({ ...foodDetails, sizes });
  };

  const handleAddOption = () => {
    const options = [...foodDetails.options, { custom_option: '', option_price: '' }];
    setFoodDetails({ ...foodDetails, options });
  };

  const handleDeleteSize = (index) => {
    const sizes = foodDetails.sizes.filter((_, i) => i !== index);
    setFoodDetails({ ...foodDetails, sizes });
  };

  const handleDeleteOption = (index) => {
    const options = foodDetails.options.filter((_, i) => i !== index);
    setFoodDetails({ ...foodDetails, options });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file && foodDetails?.id) {
      const formData = new FormData();
      formData.append('food_id', foodDetails.id);
      formData.append('image', file);

      try {
        const response = await axios.post('https://kirupam.shop/admin/admin_api/menu_management.php?action=upload_image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.message) {
          setFoodDetails({ ...foodDetails, featured_image: `uploads/${file.name}` });
          toast.success('Featured image uploaded successfully!');
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        toast.error('Error uploading image');
      }
    }
  };

  const handleGalleryChange = async (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0 && foodDetails?.id) {
      const formData = new FormData();
      formData.append('food_id', foodDetails.id);
      files.forEach((file, i) => {
        formData.append(`gallery[]`, file);
      });

      try {
        const response = await axios.post('https://kirupam.shop/admin/admin_api/menu_management.php?action=upload_gallery', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.message) {
          const gallery_images = files.map(file => `uploads/gallery/${file.name}`);
          setFoodDetails({ ...foodDetails, gallery_images });
          toast.success('Gallery images uploaded successfully!');
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        toast.error('Error uploading gallery images');
      }
    }
  };

  const handleSave = async () => {
    setLoading(true);
    await axios.post('https://kirupam.shop/admin/admin_api/menu_management.php?action=update_food', foodDetails);
    handleStoreSelect(selectedStore); // Refresh the food list
    toast.success('Food details updated successfully!');
    setLoading(false);
  };

  const handleDeleteFood = (food) => {
    setFoodToDelete(food);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteFood = async () => {
    setLoading(true);
    await axios.post('https://kirupam.shop/admin/admin_api/menu_management.php?action=delete_food', { id: foodToDelete.id });
    handleStoreSelect(selectedStore); // Refresh the food list
    toast.success('Food deleted successfully!');
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleAddFoodInputChange = (e) => {
    const { name, value } = e.target;
    setNewFoodDetails({ ...newFoodDetails, [name]: value });
  };

  const handleAddFoodSizeChange = (index, e) => {
    const { name, value } = e.target;
    const sizes = [...newFoodDetails.sizes];
    sizes[index][name] = value;
    setNewFoodDetails({ ...newFoodDetails, sizes });
  };

  const handleAddFoodOptionChange = (index, e) => {
    const { name, value } = e.target;
    const options = [...newFoodDetails.options];
    options[index][name] = value;
    setNewFoodDetails({ ...newFoodDetails, options });
  };

  const handleAddNewSize = () => {
    const sizes = [...newFoodDetails.sizes, { size: '', size_price: '' }];
    setNewFoodDetails({ ...newFoodDetails, sizes });
  };

  const handleAddNewOption = () => {
    const options = [...newFoodDetails.options, { custom_option: '', option_price: '' }];
    setNewFoodDetails({ ...newFoodDetails, options });
  };

  const handleDeleteNewSize = (index) => {
    const sizes = newFoodDetails.sizes.filter((_, i) => i !== index);
    setNewFoodDetails({ ...newFoodDetails, sizes });
  };

  const handleDeleteNewOption = (index) => {
    const options = newFoodDetails.options.filter((_, i) => i !== index);
    setNewFoodDetails({ ...newFoodDetails, options });
  };

  const handleSaveNewFood = async () => {
    setLoading(true);
    await axios.post('https://kirupam.shop/admin/admin_api/menu_management.php?action=add_food', newFoodDetails);
    handleStoreSelect(selectedStore); // Refresh the food list
    toast.success('New food added successfully!');
    setAddFoodDialogOpen(false);
    setLoading(false);
  };

  const handleChangeStorePage = (event, newPage) => {
    setStorePage(newPage);
  };

  const handleChangeStoreRowsPerPage = (event) => {
    setStoreRowsPerPage(parseInt(event.target.value, 10));
    setStorePage(0);
  };

  const handleChangeFoodPage = (event, newPage) => {
    setFoodPage(newPage);
  };

  const handleChangeFoodRowsPerPage = (event) => {
    setFoodRowsPerPage(parseInt(event.target.value, 10));
    setFoodPage(0);
  };

  const area = sessionStorage.getItem('area');
  const filteredStores = area === 'all'
    ? stores.filter(store => store.name.toLowerCase().includes(searchStore.toLowerCase()))
    : stores.filter(store => area.includes(store.location) && store.name.toLowerCase().includes(searchStore.toLowerCase()));
  const filteredFoods = foods.filter(food => food.title.toLowerCase().includes(searchFood.toLowerCase()));

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <ToastContainer />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          <h2>Stores</h2>
          <TextField
            label="Search Stores"
            variant="outlined"
            value={searchStore}
            onChange={(e) => setSearchStore(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'tomato' }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Store</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStores.slice(storePage * storeRowsPerPage, storePage * storeRowsPerPage + storeRowsPerPage).map((store) => (
                  <TableRow key={store.id} hover sx={{ cursor: 'pointer' }}>
                    <TableCell onClick={() => handleStoreSelect(store)}>{store.id}</TableCell>
                    <TableCell onClick={() => handleStoreSelect(store)}>{store.name}</TableCell>
                    <TableCell>
                      <Button variant="contained" color="primary" onClick={() => {
                        setNewFoodDetails({ ...newFoodDetails, store_id: store.id });
                        setAddFoodDialogOpen(true);
                      }}>
                        Add New Food
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredStores.length}
              page={storePage}
              onPageChange={handleChangeStorePage}
              rowsPerPage={storeRowsPerPage}
              onRowsPerPageChange={handleChangeStoreRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </TableContainer>
        </Box>
        <Box sx={{ flex: 1 }}>
          <h2>Foods</h2>
          {selectedStore && (
            <>
              <TextField
                label="Search Foods"
                variant="outlined"
                value={searchFood}
                onChange={(e) => setSearchFood(e.target.value)}
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: 'tomato' }}>
                      <TableCell>ID</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredFoods.slice(foodPage * foodRowsPerPage, foodPage * foodRowsPerPage + foodRowsPerPage).map((food) => (
                      <TableRow key={food.id} hover sx={{ cursor: 'pointer' }}>
                        <TableCell onClick={() => handleFoodSelect(food)}>{food.id}</TableCell>
                        <TableCell onClick={() => handleFoodSelect(food)}>{food.title}</TableCell>
                        <TableCell>
                          <Button variant="contained" color="error" onClick={() => handleDeleteFood(food)}>
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  count={filteredFoods.length}
                  page={foodPage}
                  onPageChange={handleChangeFoodPage}
                  rowsPerPage={foodRowsPerPage}
                  onRowsPerPageChange={handleChangeFoodRowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                />
              </TableContainer>
            </>
          )}
        </Box>
        {selectedFood && (
          <Box component="form" sx={{ flex: 1 }}>
            <h2>Food Details</h2>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TextField
                  label="Title"
                  name="title"
                  value={foodDetails?.title || ''}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Base Price"
                  name="base_price"
                  value={foodDetails?.base_price || ''}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Quantity"
                  name="quantity"
                  value={foodDetails?.quantity || ''}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Description"
                  name="des"
                  value={foodDetails?.des || ''}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Category</InputLabel>
                  <Select
                    name="category_id"
                    value={foodDetails?.category_id || ''}
                    onChange={handleInputChange}
                  >
                    {categories.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="Stock"
                  name="stock"
                  value={foodDetails?.stock || ''}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel>Food Time</InputLabel>
                  <Select
                    name="foodtime"
                    value={foodDetails?.foodtime || ''}
                    onChange={handleInputChange}
                  >
                    <MenuItem value="Breakfast">Breakfast</MenuItem>
                    <MenuItem value="Brunch">Brunch</MenuItem>
                    <MenuItem value="Lunch">Lunch</MenuItem>
                    <MenuItem value="Snacks">Snacks</MenuItem>
                    <MenuItem value="Dinner">Dinner</MenuItem>
                    <MenuItem value="Fast Food">Fast Food</MenuItem>
                  </Select>
                </FormControl>

                <h3>Sizes</h3>
                {foodDetails?.sizes?.map((size, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <TextField
                      label="Size"
                      name="size"
                      value={size.size}
                      onChange={(e) => handleSizeChange(index, e)}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                    <TextField
                      label="Price"
                      name="size_price"
                      value={size.size_price}
                      onChange={(e) => handleSizeChange(index, e)}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                    <Button variant="contained" color="secondary" onClick={() => handleDeleteSize(index)} sx={{ mb: 1 }}>
                      Delete Size
                    </Button>
                  </Box>
                ))}
                <Button variant="contained" color="secondary" onClick={handleAddSize} sx={{ mb: 2 }}>
                  Add Size
                </Button>
                <h3>Options</h3>
                {foodDetails?.options?.map((option, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <TextField
                      label="Option"
                      name="custom_option"
                      value={option.custom_option}
                      onChange={(e) => handleOptionChange(index, e)}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                    <TextField
                      label="Price"
                      name="option_price"
                      value={option.option_price}
                      onChange={(e) => handleOptionChange(index, e)}
                      fullWidth
                      sx={{ mb: 1 }}
                    />
                    <Button variant="contained" color="secondary" onClick={() => handleDeleteOption(index)} sx={{ mb: 1 }}>
                      Delete Option
                    </Button>
                  </Box>
                ))}
                <Button variant="contained" color="secondary" onClick={handleAddOption} sx={{ mb: 2 }}>
                  Add Option
                </Button>
                <h3>Featured Image</h3>
                <input type="file" onChange={handleImageChange} />
                {foodDetails?.featured_image && (
                  <Box component="img" src={`https://kirupam.shop/admin/${foodDetails.featured_image}`} alt="Featured" sx={{ width: '100%', mt: 2 }} />
                )}
                <h3>Gallery Images</h3>
                <input type="file" multiple onChange={handleGalleryChange} />
                {Array.isArray(foodDetails?.gallery_images) && foodDetails?.gallery_images.map((img, index) => (
                  <Box component="img" key={index} src={`https://kirupam.shop/admin/admin_api/${img}`} alt={`Gallery ${index}`} sx={{ width: '100%', mt: 2 }} />
                ))}
                <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
                  Save
                </Button>
              </>
            )}
          </Box>
        )}
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Food</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this food item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteFood} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={addFoodDialogOpen}
        onClose={() => setAddFoodDialogOpen(false)}
      >
        <DialogTitle>Add New Food</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details for the new food item.
          </DialogContentText>
          <TextField
            label="Title"
            name="title"
            value={newFoodDetails.title}
            onChange={handleAddFoodInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Base Price"
            name="base_price"
            value={newFoodDetails.base_price}
            onChange={handleAddFoodInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Quantity"
            name="quantity"
            value={newFoodDetails.quantity}
            onChange={handleAddFoodInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Description"
            name="des"
            value={newFoodDetails.des}
            onChange={handleAddFoodInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Category</InputLabel>
            <Select
              name="category_id"
              value={newFoodDetails.category_id}
              onChange={handleAddFoodInputChange}
            >
              {categories.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  {category.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Stock"
            name="stock"
            value={newFoodDetails.stock}
            onChange={handleAddFoodInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Food Time</InputLabel>
            <Select
              name="foodtime"
              value={newFoodDetails.foodtime}
              onChange={handleAddFoodInputChange}
            >
              <MenuItem value="Breakfast">Breakfast</MenuItem>
              <MenuItem value="Brunch">Brunch</MenuItem>
              <MenuItem value="Lunch">Lunch</MenuItem>
              <MenuItem value="Snacks">Snacks</MenuItem>
              <MenuItem value="Dinner">Dinner</MenuItem>
              <MenuItem value="Fast Food">Fast Food</MenuItem>
            </Select>
          </FormControl>

          <h3>Sizes</h3>
          {newFoodDetails.sizes.map((size, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <TextField
                label="Size"
                name="size"
                value={size.size}
                onChange={(e) => handleAddFoodSizeChange(index, e)}
                fullWidth
                sx={{ mb: 1 }}
              />
              <TextField
                label="Price"
                name="size_price"
                value={size.size_price}
                onChange={(e) => handleAddFoodSizeChange(index, e)}
                fullWidth
                sx={{ mb: 1 }}
              />
              <Button variant="contained" color="secondary" onClick={() => handleDeleteNewSize(index)} sx={{ mb: 1 }}>
                Delete Size
              </Button>
            </Box>
          ))}
          <Button variant="contained" color="secondary" onClick={handleAddNewSize} sx={{ mb: 2 }}>
            Add Size
          </Button>
          <h3>Options</h3>
          {newFoodDetails.options.map((option, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <TextField
                label="Option"
                name="custom_option"
                value={option.custom_option}
                onChange={(e) => handleAddFoodOptionChange(index, e)}
                fullWidth
                sx={{ mb: 1 }}
              />
              <TextField
                label="Price"
                name="option_price"
                value={option.option_price}
                onChange={(e) => handleAddFoodOptionChange(index, e)}
                fullWidth
                sx={{ mb: 1 }}
              />
              <Button variant="contained" color="secondary" onClick={() => handleDeleteNewOption(index)} sx={{ mb: 1 }}>
                Delete Option
              </Button>
            </Box>
          ))}
          <Button variant="contained" color="secondary" onClick={handleAddNewOption} sx={{ mb: 2 }}>
            Add Option
          </Button>
          <h3>Featured Image</h3>
          <input type="file" onChange={(e) => handleImageChange(e)} />
          {newFoodDetails.featured_image && (
            <Box component="img" src={`https://kirupam.shop/admin/admin_api/${newFoodDetails.featured_image}`} alt="Featured" sx={{ width: '100%', mt: 2 }} />
          )}
          <h3>Gallery Images</h3>
          <input type="file" multiple onChange={(e) => handleGalleryChange(e)} />
          {Array.isArray(newFoodDetails.gallery_images) && newFoodDetails.gallery_images.map((img, index) => (
            <Box component="img" key={index} src={`https://kirupam.shop/admin/admin_api/${img}`} alt={`Gallery ${index}`} sx={{ width: '100%', mt: 2 }} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddFoodDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveNewFood} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Menu;
