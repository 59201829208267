import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileCopyIcon from '@mui/icons-material/FileCopy'; // Import the duplicate icon
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const StoreManagement = () => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeDetails, setStoreDetails] = useState(null);
  const [searchStore, setSearchStore] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [mapPosition, setMapPosition] = useState([7.483782495960556, 80.36286672973635]);
  const [addStoreDialogOpen, setAddStoreDialogOpen] = useState(false);
  const [newStore, setNewStore] = useState({
    name: '', address: '', contact_number: '', username: '', email: '',
    phone_number: '', password: '', reviews: '', avl: '', hours: '',
    visible: '', start_time: '', end_time: '', slot: '', aprove: '',
    offer: '', p_option: '', service: '', location: '', rating: '', featured_image: null
  });

  const districts = [
    "Ampara", "Anuradhapura", "Badulla", "Batticaloa", "Colombo", "Galle", "Gampaha", "Hambantota", 
    "Jaffna", "Kalutara", "Kandy", "Kegalle", "Kilinochchi", "Kurunegala", "Mannar", "Matale", 
    "Matara", "Monaragala", "Mullaitivu", "Nuwara Eliya", "Polonnaruwa", "Puttalam", "Ratnapura", 
    "Trincomalee", "Vavuniya"
  ];

  useEffect(() => {
    fetchStores();
  }, []);

  const fetchStores = async () => {
    setLoading(true);
    const response = await axios.get('https://kirupam.shop/admin/admin_api/store_management.php?action=fetch_stores');
    setStores(response.data);
    setLoading(false);
  };

  const handleStoreSelect = async (store) => {
    setLoading(true);
    setSelectedStore(store);
    const response = await axios.get(`https://kirupam.shop/admin/admin_api/store_management.php?action=fetch_store_details&store_id=${store.id}`);
    setStoreDetails(response.data);
    const location = response.data.address ? response.data.address.split(',') : [7.483782495960556, 80.36286672973635];
    setMapPosition([parseFloat(location[0]), parseFloat(location[1])]);
    setLoading(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails({ ...storeDetails, [name]: value });
  };

  const handleMapClick = (e) => {
    const { lat, lng } = e.latlng;
    setMapPosition([lat, lng]);
    setStoreDetails({ ...storeDetails, address: `${lat},${lng}` });
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: handleMapClick,
    });
    return null;
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('store_id', storeDetails.id);
      formData.append('image', file);

      try {
        const response = await axios.post('https://kirupam.shop/admin/admin_api/store_management.php?action=upload_image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        if (response.data.message) {
          setStoreDetails({ ...storeDetails, featured_image: `uploads/${file.name}` });
          toast.success('Featured image uploaded successfully!');
        } else {
          toast.error(response.data.error);
        }
      } catch (error) {
        toast.error('Error uploading image');
      }
    }
  };

  const handleSave = async () => {
    setLoading(true);
    await axios.post('https://kirupam.shop/admin/admin_api/store_management.php?action=update_store', storeDetails);
    fetchStores(); // Refresh the store list
    toast.success('Store details updated successfully!');
    setLoading(false);
  };

  const handleDeleteStore = (store) => {
    setSelectedStore(store);
    setDeleteDialogOpen(true);
  };

  const confirmDeleteStore = async () => {
    setLoading(true);
    await axios.post('https://kirupam.shop/admin/admin_api/store_management.php?action=delete_store', { id: selectedStore.id });
    fetchStores(); // Refresh the store list
    toast.success('Store deleted successfully!');
    setDeleteDialogOpen(false);
    setLoading(false);
  };

  const handleDuplicateStore = async (store) => {
    setLoading(true);
    try {
      await axios.get(`https://kirupam.shop/admin/admin_api/duplicate.php?store_id=${store.id}`);
      fetchStores(); // Refresh the store list
      toast.success('Store duplicated successfully!');
    } catch (error) {
      toast.error('Error duplicating store');
    }
    setLoading(false);
  };

  const handleAddStoreChange = (e) => {
    const { name, value } = e.target;
    setNewStore({ ...newStore, [name]: value });
  };

  const handleAddStoreImageChange = (e) => {
    const file = e.target.files[0];
    setNewStore({ ...newStore, featured_image: file });
  };

  const handleAddStore = async () => {
    setLoading(true);
    const formData = new FormData();
    Object.keys(newStore).forEach(key => {
      formData.append(key, newStore[key]);
    });
    await axios.post('https://kirupam.shop/admin/admin_api/store_management.php?action=add_store', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    fetchStores(); // Refresh the store list
    setAddStoreDialogOpen(false);
    setNewStore({
      name: '', address: '', contact_number: '', username: '', email: '',
      phone_number: '', password: '', reviews: '', avl: '', hours: '',
      visible: '', start_time: '', end_time: '', slot: '', aprove: '',
      offer: '', p_option: '', service: '', location: '', rating: '', featured_image: null
    });
    toast.success('Store added successfully!');
    setLoading(false);
  };

  const area = sessionStorage.getItem('area');
  const filteredStores = area === 'all'
    ? stores.filter(store => store.name.toLowerCase().includes(searchStore.toLowerCase()))
    : stores.filter(store => area.includes(store.location) && store.name.toLowerCase().includes(searchStore.toLowerCase()));

  return (
    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
      <ToastContainer />
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ flex: 1 }}>
          <h2>Stores</h2>
          <TextField
            label="Search Stores"
            variant="outlined"
            value={searchStore}
            onChange={(e) => setSearchStore(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={() => setAddStoreDialogOpen(true)} sx={{ mb: 2 }}>
            Add New Store
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: 'tomato' }}>
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Featured Image</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStores.map((store) => (
                  <TableRow key={store.id} hover sx={{ cursor: 'pointer' }}>
                    <TableCell onClick={() => handleStoreSelect(store)}>{store.id}</TableCell>
                    <TableCell onClick={() => handleStoreSelect(store)}>{store.name}</TableCell>
                    <TableCell>
                      {store.featured_image && (
                        <Box component="img" src={`https://kirupam.shop/admin/${store.featured_image}`} alt="Featured" sx={{ width: '100px', height: '100px' }} />
                      )}
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="error" onClick={() => handleDeleteStore(store)}>
                        Delete
                      </Button>
                      <Button variant="contained" color="primary" onClick={() => handleDuplicateStore(store)} startIcon={<FileCopyIcon />}>
                        Duplicate
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {selectedStore && (
          <Box component="form" sx={{ flex: 1 }}>
            <h2>Store Details</h2>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </Box>
            ) : (
              storeDetails && (
                <>
                  <TextField
                    label="Name"
                    name="name"
                    value={storeDetails?.name || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <MapContainer center={mapPosition} zoom={13} style={{ height: '300px', width: '100%' }}>
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="lemmda.com">Lemda</a> contributors'
                    />
                    <Marker position={mapPosition}>
                      <MapClickHandler />
                    </Marker>
                  </MapContainer>
                  <TextField
                    label="Address"
                    name="address"
                    value={storeDetails?.address || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Contact Number"
                    name="contact_number"
                    value={storeDetails?.contact_number || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Username"
                    name="username"
                    value={storeDetails?.username || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Email"
                    name="email"
                    value={storeDetails?.email || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Phone Number"
                    name="phone_number"
                    value={storeDetails?.phone_number || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Password"
                    name="password"
                    type="password"
                    value={storeDetails?.password || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Reviews"
                    name="reviews"
                    value={storeDetails?.reviews || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Availability</InputLabel>
                    <Select
                      name="avl"
                      value={storeDetails?.avl || ''}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="1">Open</MenuItem>
                      <MenuItem value="0">Closed</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Hours"
                    name="hours"
                    value={storeDetails?.hours || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Visible</InputLabel>
                    <Select
                      name="visible"
                      value={storeDetails?.visible || ''}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="1visi">Visible</MenuItem>
                      <MenuItem value="0unvisi">Invisible</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Start Time"
                    name="start_time"
                    value={storeDetails?.start_time || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="End Time"
                    name="end_time"
                    value={storeDetails?.end_time || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    label="Slot"
                    name="slot"
                    value={storeDetails?.slot || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Approve</InputLabel>
                    <Select
                      name="aprove"
                      value={storeDetails?.aprove || ''}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="1">Approved</MenuItem>
                      <MenuItem value="0">Not Approved</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Offer"
                    name="offer"
                    value={storeDetails?.offer || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Payment Option</InputLabel>
                    <Select
                      name="p_option"
                      value={storeDetails?.p_option || ''}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="1">Card and Wallet</MenuItem>
                      <MenuItem value="0">Cash and Card</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Service"
                    name="service"
                    value={storeDetails?.service || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Location</InputLabel>
                    <Select
                      name="location"
                      value={storeDetails?.location || ''}
                      onChange={handleInputChange}
                    >
                      {districts.map((district, index) => (
                        <MenuItem key={index} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Rating"
                    name="rating"
                    value={storeDetails?.rating || ''}
                    onChange={handleInputChange}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <h3>Featured Image</h3>
                  <input type="file" onChange={handleImageChange} />
                  {storeDetails?.featured_image && (
                    <Box component="img" src={`https://kirupam.shop/admin/${storeDetails.featured_image}`} alt="Featured" sx={{ width: '100%', mt: 2 }} />
                  )}
                  <Button variant="contained" color="primary" onClick={handleSave} sx={{ mt: 2 }}>
                    Save
                  </Button>
                </>
              )
            )}
          </Box>
        )}
      </Box>

      <Dialog
        open={addStoreDialogOpen}
        onClose={() => setAddStoreDialogOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Add New Store</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Name"
                name="name"
                value={newStore.name}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Contact Number"
                name="contact_number"
                value={newStore.contact_number}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Username"
                name="username"
                value={newStore.username}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                name="email"
                value={newStore.email}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone Number"
                name="phone_number"
                value={newStore.phone_number}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Password"
                name="password"
                type="password"
                value={newStore.password}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Reviews"
                name="reviews"
                value={newStore.reviews}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Availability</InputLabel>
                <Select
                  name="avl"
                  value={newStore.avl}
                  onChange={handleAddStoreChange}
                >
                  <MenuItem value="Open">Open</MenuItem>
                  <MenuItem value="Closed">Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Hours"
                name="hours"
                value={newStore.hours}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Visible</InputLabel>
                <Select
                  name="visible"
                  value={newStore.visible}
                  onChange={handleAddStoreChange}
                >
                  <MenuItem value="visi">Visible</MenuItem>
                  <MenuItem value="unvisi">Invisible</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Start Time"
                name="start_time"
                value={newStore.start_time}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="End Time"
                name="end_time"
                value={newStore.end_time}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Slot"
                name="slot"
                value={newStore.slot}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Approve</InputLabel>
                <Select
                  name="aprove"
                  value={newStore.aprove}
                  onChange={handleAddStoreChange}
                >
                  <MenuItem value="1">Approved</MenuItem>
                  <MenuItem value="0">Not Approved</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Offer"
                name="offer"
                value={newStore.offer}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Payment Option</InputLabel>
                <Select
                  name="p_option"
                  value={newStore.p_option}
                  onChange={handleAddStoreChange}
                >
                  <MenuItem value="1">Card and Wallet</MenuItem>
                  <MenuItem value="0">Cash and Card</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Service"
                name="service"
                value={newStore.service}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Location</InputLabel>
                <Select
                  name="location"
                  value={newStore.location}
                  onChange={handleAddStoreChange}
                >
                  {districts.map((district, index) => (
                    <MenuItem key={index} value={district}>
                      {district}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Rating"
                name="rating"
                value={newStore.rating}
                onChange={handleAddStoreChange}
                fullWidth
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Featured Image</h3>
              <input type="file" onChange={handleAddStoreImageChange} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddStoreDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddStore} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Store</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this store?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteStore} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StoreManagement;
