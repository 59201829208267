import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import Dashboard from './components/Dashboard';
import Orders from './components/Orders';
import Menu from './components/Menu';
import Settings from './components/Settings';
import { CssBaseline, Box, Toolbar, Alert, Snackbar } from '@mui/material';
import StoreManagement from './components/StoreManagement';
import UserManagement from './components/UserManagement';
import Login from './components/Login';
import RiderTracking from './components/RiderTracking';
import NotificationSound from './components/NotificationSound';
import OfferManagement from './components/OfferManagement';
import DeliveryRiderManagement from './components/DeliveryRiderManagement'; 
import TaskManagement from './components/TaskManagement';
import SalaryManagement from './components/SalaryManagement';
import EditOrder from './components/EditOrder';

const drawerWidth = 240;

const App = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOfflineAlert, setShowOfflineAlert] = useState(false);
  const [showOnlineAlert, setShowOnlineAlert] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogin = (type) => {
    setLoggedIn(true);
    setUserType(type);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    setUserType('');
  };

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
      setShowOnlineAlert(true);
      setTimeout(() => setShowOnlineAlert(false), 3000);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowOfflineAlert(true);
      setTimeout(() => setShowOfflineAlert(false), 3000);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (!loggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Navbar handleDrawerToggle={handleDrawerToggle} onLogout={handleLogout} />
        <Box sx={{ display: 'flex' }}>
          <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/orders/edit/:orderId" element={<EditOrder />} /> {/* Add this line */}
              <Route path="/menu" element={<Menu />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/store-management" element={<StoreManagement />} />
              <Route path="/user-management" element={<UserManagement />} />
              <Route path="/rider-tracking" element={<RiderTracking />} />
              <Route path="/offer-management" element={<OfferManagement />} />
              <Route path="/delivery-rider-management" element={<DeliveryRiderManagement />} />
              <Route path="/task-management" element={<TaskManagement />} /> {/* Add TaskManagement Route */}
              <Route path="/salary-management" element={<SalaryManagement />} /> 
            </Routes>
          </Box>
        </Box>
      </Router>
      <NotificationSound /> {/* Ensure this component is always running */}
      
      <Snackbar open={showOfflineAlert} autoHideDuration={3000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          You are offline. Please check your internet connection.
        </Alert>
      </Snackbar>
      <Snackbar open={showOnlineAlert} autoHideDuration={3000}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Internet connection restored.
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default App;
