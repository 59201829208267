import React, { useState } from 'react';
import { Tabs, Tab, TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';

const Login = ({ onLogin }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    let userType;
    if (tabIndex === 0) {
      userType = 'admin';
    } else if (tabIndex === 1) {
      userType = 'kride_admin';
    }
    try {
      const response = await axios.post(`https://kirupam.shop/admin/admin_api/login.php`, {
        username,
        password,
        userType,
      });

      if (response.data.success) {
        const { id, area } = response.data.user;
        sessionStorage.setItem('userId', id);
        sessionStorage.setItem('area', area);
        onLogin(userType);
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login error');
    }
  };

  return (
    <Box sx={{ width: '300px', margin: '0 auto', textAlign: 'center', marginTop: '200px' }}>
      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Kirupam Admin" />
      </Tabs>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={handleUsernameChange}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={handlePasswordChange}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLogin}
          sx={{ mt: 2 }}
        >
          Login
        </Button>
        <Button
          variant="contained"
          color="primary"
          href="https://rider.kirupam.com"
          sx={{ mt: 2, ml:2 }}
        >
          Go to Rider Login
        </Button>
      </Box>
      <Typography variant="body2" color="gray" align="center" sx={{ mt: 4 }}>
        Developed by Lemda (Pvt) Ltd
      </Typography>
    </Box>
  );
};

export default Login;
