import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const DEFAULT_CENTER = { lat: 8.7512, lng: 80.4975 }; // Vavuniya, Sri Lanka

const TaskManagement = () => {
  const [tasks, setTasks] = useState([]);
  const [riders, setRiders] = useState([]);
  const [newTask, setNewTask] = useState({
    title: '',
    description: '',
    status: '',
    assigned_rider_id: '',
    storeLocation: DEFAULT_CENTER,
    deliveryLocation: DEFAULT_CENTER,
    deliveryCharge: ''
  });
  const [editTask, setEditTask] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTasks();
    fetchRiders();
  }, []);

  const fetchTasks = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/task_management.php?action=fetch_tasks');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRiders = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/task_management.php?action=fetch_riders');
      setRiders(response.data);
    } catch (error) {
      console.error('Error fetching riders:', error);
    }
  };

  const handleAddOrEditTask = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const taskData = {
        ...newTask,
        storeLocationLat: newTask.storeLocation.lat,
        storeLocationLng: newTask.storeLocation.lng,
        deliveryLocationLat: newTask.deliveryLocation.lat,
        deliveryLocationLng: newTask.deliveryLocation.lng,
        deliveryCharge: calculateDeliveryCharge(newTask.storeLocation, newTask.deliveryLocation)
      };
      if (editTask) {
        await axios.post(`https://kirupam.shop/admin/admin_api/task_management.php?action=update_task`, { ...taskData, id: editTask.id });
        toast.success('Task updated successfully!');
      } else {
        await axios.post('https://kirupam.shop/admin/admin_api/task_management.php?action=add_task', taskData);
        toast.success('Task added successfully!');
      }
      fetchTasks();
      handleClose();
    } catch (error) {
      console.error('Error adding/updating task:', error);
      toast.error('Error adding/updating task');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (task) => {
    setEditTask(task);
    setNewTask({
      ...task,
      storeLocation: { lat: task.store_location_lat, lng: task.store_location_lng },
      deliveryLocation: { lat: task.delivery_location_lat, lng: task.delivery_location_lng },
    });
    setOpen(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      await axios.post('https://kirupam.shop/admin/admin_api/task_management.php?action=delete_task', { id });
      toast.success('Task deleted successfully!');
      fetchTasks();
    } catch (error) {
      console.error('Error deleting task:', error);
      toast.error('Error deleting task');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewTask({ ...newTask, [name]: value });
  };

  const handleLocationChange = (name, latLng) => {
    setNewTask({ ...newTask, [name]: latLng });
  };

  const handleClose = () => {
    setOpen(false);
    setEditTask(null);
    setNewTask({ title: '', description: '', status: '', assigned_rider_id: '', storeLocation: DEFAULT_CENTER, deliveryLocation: DEFAULT_CENTER, deliveryCharge: '' });
  };

  const calculateDeliveryCharge = (storeLocation, deliveryLocation) => {
    const distance = getDistanceFromLatLonInKm(storeLocation.lat, storeLocation.lng, deliveryLocation.lat, deliveryLocation.lng);
    return distance <= 1 ? 60 : 60 + ((distance - 1) * 50);
  };

  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    const R = 6371;
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <ToastContainer />
      {loading && <CircularProgress />}
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} sx={{ mb: 2 }}>
        Add New Task
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editTask ? 'Edit Task' : 'Add New Task'}</DialogTitle>
        <form onSubmit={handleAddOrEditTask}>
          <DialogContent>
            <TextField
              autoFocus
              margin="normal"
              name="title"
              label="Title"
              type="text"
              fullWidth
              value={newTask.title}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              name="description"
              label="Description"
              type="text"
              fullWidth
              value={newTask.description}
              onChange={handleChange}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={newTask.status}
                onChange={handleChange}
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="in_progress">In Progress</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Assigned Rider</InputLabel>
              <Select
                name="assigned_rider_id"
                value={newTask.assigned_rider_id}
                onChange={handleChange}
              >
                {riders.map((rider) => (
                  <MenuItem key={rider.id} value={rider.id}>
                    {rider.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LeafletMap
              center={newTask.storeLocation}
              onLocationChange={(latLng) => handleLocationChange('storeLocation', latLng)}
              label="Select Store Location"
              mapId="storeMap"
            />
            <LeafletMap
              center={newTask.deliveryLocation}
              onLocationChange={(latLng) => handleLocationChange('deliveryLocation', latLng)}
              label="Select Delivery Location"
              mapId="deliveryMap"
            />
            <TextField
              margin="normal"
              name="deliveryCharge"
              label="Calculated Delivery Charge"
              type="text"
              fullWidth
              value={calculateDeliveryCharge(newTask.storeLocation, newTask.deliveryLocation)}
              disabled
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button type="submit" color="primary" disabled={loading}>{editTask ? 'Save' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assigned Rider</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.map((task) => (
              <TableRow key={task.id}>
                <TableCell>{task.title}</TableCell>
                <TableCell>{task.description}</TableCell>
                <TableCell>{task.status}</TableCell>
                <TableCell>{task.rider_name || 'Not Assigned'}</TableCell>
                <TableCell>{task.created_at}</TableCell>
                <TableCell>{task.updated_at}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(task)} disabled={loading}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(task.id)} disabled={loading}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const LeafletMap = ({ center, onLocationChange, label, mapId }) => {
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.remove(); // Remove the previous map instance
    }
    mapRef.current = L.map(mapId).setView(center, 14);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(mapRef.current);

    markerRef.current = L.marker(center, { draggable: true }).addTo(mapRef.current);
    markerRef.current.on('dragend', function (e) {
      const latLng = e.target.getLatLng();
      onLocationChange(latLng);
    });
  }, [center, mapId, onLocationChange]);

  useEffect(() => {
    return () => {
      if (mapRef.current) {
        mapRef.current.remove(); // Clean up the map instance on component unmount
      }
    };
  }, []);

  return (
    <Box sx={{ height: '400px', width: '100%', mt: 2 }}>
      <Typography variant="h6">{label}</Typography>
      <div id={mapId} style={{ height: '100%', width: '100%' }}></div>
    </Box>
  );
};

export default TaskManagement;
