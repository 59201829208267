import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  Grid,
  Button,
  Divider,
  TextField,
} from '@mui/material';
import { styled } from '@mui/system';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import 'leaflet/dist/leaflet.css';
import './RiderTracking.css';

// Default center is set to Sri Lanka
const defaultCenter = {
  lat: 7.8731,
  lng: 80.7718,
};

// Custom rider marker icon
const riderMarkerIcon = new L.Icon({
  iconUrl: 'https://kirupam.shop/admin/admin_api/rider.png',
  iconSize: [38, 95],
  iconAnchor: [22, 94],
  popupAnchor: [-3, -76],
  shadowUrl: 'https://leafletjs.com/examples/custom-icons/leaf-shadow.png',
  shadowSize: [50, 64],
  shadowAnchor: [4, 62],
});

const RiderTracking = () => {
  const [riders, setRiders] = useState([]);
  const [selectedRider, setSelectedRider] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [loading, setLoading] = useState(true);
  const [notification, setNotification] = useState({ title: '', description: '', details: '' });

  useEffect(() => {
    fetchRiderLocations();
  }, []);

  const fetchRiderLocations = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/rider_tracking.php?action=fetch_locations');
      setRiders(response.data);
      if (response.data.length > 0) {
        setSelectedRider(response.data[0]);
        setMapCenter({
          lat: response.data[0].current_location_latitude,
          lng: response.data[0].current_location_longitude,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching rider locations:', error);
      setLoading(false);
    }
  };

  const handleRiderClick = (rider) => {
    setSelectedRider(rider);
    setMapCenter({ lat: rider.current_location_latitude, lng: rider.current_location_longitude });
  };

  const MapUpdater = ({ center }) => {
    const map = useMap();
    map.flyTo(center, 17, { animate: true });
    return null;
  };

  const handleNotificationSubmit = async () => {
    try {
      const response = await axios.post('https://kirupam.shop/admin/admin_api/add_notification.php', notification);
      if (response.data.success) {
        alert('Notification sent successfully!');
        setNotification({ title: '', description: '', details: '' });
      } else {
        alert('Failed to send notification');
      }
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box display="flex" height="100vh">
      <Box width="25%" p={2} bgcolor="background.paper" sx={{ overflowY: 'auto' }}>
        <Card>
          <CardHeader title="Riders" />
          <CardContent>
            <List>
              {riders.map((rider) => (
                <ListItem button key={rider.id} onClick={() => handleRiderClick(rider)}>
                  <Avatar> {rider.name.charAt(0)}</Avatar> 
                  <ListItemText primary={rider.name} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Box>
      <Box width="75%" p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardHeader title="Rider Location" />
              <CardContent>
                <MapContainer center={mapCenter} zoom={12} style={{ width: '100%', height: '60vh' }}>
                  <MapUpdater center={mapCenter} />
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.lemmda.com">LEMDA</a> contributors'
                  />
                  {selectedRider && (
                    <Marker
                      position={[selectedRider.current_location_latitude, selectedRider.current_location_longitude]}
                      icon={riderMarkerIcon}
                    >
                      <Popup>
                        <Typography variant="body1"><strong>Rider:</strong> {selectedRider.name}</Typography>
                        <Typography variant="body2"><strong>Latitude:</strong> {selectedRider.current_location_latitude}</Typography>
                        <Typography variant="body2"><strong>Longitude:</strong> {selectedRider.current_location_longitude}</Typography>
                      </Popup>
                    </Marker>
                  )}
                </MapContainer>
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <CardHeader title="Send Notification" />
              <CardContent>
                <Box component="form" noValidate autoComplete="off">
                  <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={notification.title}
                    onChange={(e) => setNotification({ ...notification, title: e.target.value })}
                  />
                  <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={notification.description}
                    onChange={(e) => setNotification({ ...notification, description: e.target.value })}
                  />
                  <TextField
                    label="Details"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    value={notification.details}
                    onChange={(e) => setNotification({ ...notification, details: e.target.value })}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNotificationSubmit}
                    sx={{ mt: 2 }}
                  >
                    Send Notification
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default RiderTracking;
