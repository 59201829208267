import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Box, MenuItem, FormControl, InputLabel, Select, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const OfferManagement = () => {
  const [banners, setBanners] = useState('');
  const [store, setStore] = useState('');
  const [percentage, setPercentage] = useState('');
  const [validTill, setValidTill] = useState('');
  const [stores, setStores] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editOffer, setEditOffer] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchStores();
    fetchOffers();
  }, []);

  const fetchStores = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/fetch_stores.php');
      setStores(response.data.stores || []);
    } catch (error) {
      console.error('Error fetching stores:', error);
      setStores([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchOffers = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/add_offer.php?action=fetch');
      setOffers(response.data.offers || []);
    } catch (error) {
      console.error('Error fetching offers:', error);
      setOffers([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append('banners', selectedFile || banners);
    formData.append('store', store);
    formData.append('percentage', percentage);
    formData.append('validTill', validTill);
    formData.append('id', editOffer ? editOffer.id : '');

    const url = `https://kirupam.shop/admin/admin_api/add_offer.php?action=${editOffer ? 'edit' : 'add'}`;

    try {
      const response = await axios.post(url, formData);
      if (response.data.success) {
        toast.success('Offer saved successfully!');
        fetchOffers();
        handleClose();
      } else {
        toast.error('Error saving offer');
      }
    } catch (error) {
      toast.error('Error saving offer');
      console.error('Error saving offer:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this offer?')) return;
    setLoading(true);
    try {
        const formData = new FormData();
        formData.append('id', id);

        const response = await axios.post('https://kirupam.shop/admin/admin_api/add_offer.php?action=delete', formData);
        if (response.data.success) {
            toast.success('Offer deleted successfully!');
            fetchOffers();
        } else {
            toast.error('Error deleting offer');
        }
    } catch (error) {
        toast.error('Error deleting offer');
        console.error('Error deleting offer:', error);
    } finally {
        setLoading(false);
    }
};


  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleEdit = (offer) => {
    setEditOffer(offer);
    setBanners(offer.banners);
    setStore(offer.store_id);
    setPercentage(offer.percentage);
    setValidTill(offer.valid_till);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditOffer(null);
    setBanners('');
    setStore('');
    setPercentage('');
    setValidTill('');
    setSelectedFile(null);
  };

  return (
    <Box sx={{ mt: 3 }}>
      <ToastContainer />
      {loading && <CircularProgress />}
      <Button variant="contained" color="primary" onClick={() => setOpen(true)} sx={{ mb: 2 }}>
        Add New Offer
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editOffer ? 'Edit Offer' : 'Add New Offer'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              label="Banner"
              type="file"
              fullWidth
              margin="normal"
              onChange={handleFileChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Store</InputLabel>
              <Select
                value={store}
                onChange={(e) => setStore(e.target.value)}
                label="Store"
              >
                {stores.map((store) => (
                  <MenuItem key={store.id} value={store.id}>
                    {store.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Percentage"
              type="number"
              fullWidth
              margin="normal"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
            />
            <TextField
              label="Valid Till"
              type="date"
              fullWidth
              margin="normal"
              value={validTill}
              onChange={(e) => setValidTill(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancel</Button>
            <Button type="submit" color="primary" disabled={loading}>{editOffer ? 'Save' : 'Add'}</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Banner</TableCell>
            <TableCell>Store</TableCell>
            <TableCell>Percentage</TableCell>
            <TableCell>Valid Till</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offers.map((offer) => (
            <TableRow key={offer.id}>
              <TableCell><img src={`https://kirupam.shop/admin/uploads/${offer.banners}`} alt="Banner" style={{ width: 100, height: 100 }} /></TableCell>
              <TableCell>{offer.storeName}</TableCell>
              <TableCell>{offer.percentage}</TableCell>
              <TableCell>{offer.valid_till}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(offer)} disabled={loading}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(offer.id)} disabled={loading}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default OfferManagement;
