import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Chip,
  Grid,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SalaryManagement = () => {
  const [riders, setRiders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [settings, setSettings] = useState(null);
  const [selectedRider, setSelectedRider] = useState(null);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsResponse, ridersResponse, ordersResponse, tasksResponse] = await Promise.all([
          axios.get('https://kirupam.shop/admin/admin_api/fetch_settings.php'),
          axios.get('https://kirupam.shop/admin/admin_api/ordermanagement.php?action=fetch_riders'),
          axios.get('https://kirupam.shop/admin/admin_api/ordermanagement.php?action=fetch_orders'),
          axios.get('https://kirupam.shop/admin/admin_api/taskfetch.php')
        ]);
        setSettings(settingsResponse.data);
        setRiders(ridersResponse.data);
        setOrders(ordersResponse.data);
        setTasks(tasksResponse.data);
        setLoading(false);
      } catch (error) {
        toast.error('Failed to fetch data');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const calculateEarnings = (deliveryCharge, riderFeesPercentage, riderFeesMinimum) => {
    const earnings = (deliveryCharge * riderFeesPercentage) / 100;
    return earnings < riderFeesMinimum ? riderFeesMinimum : earnings;
  };

  const handleRiderClick = (rider) => {
    setSelectedRider(rider);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredRiders = riders.filter(rider => {
    return rider.name.toLowerCase().includes(searchQuery.toLowerCase()) || rider.id.toString().includes(searchQuery);
  });

  const filteredOrders = orders.filter(order => {
    if (selectedRider && order.asigned_delivery_rider !== selectedRider.id) {
      return false;
    }
    const orderDate = dayjs(order.created_at);
    if (startDate && orderDate.isBefore(dayjs(startDate).startOf('day'))) {
      return false;
    }
    if (endDate && orderDate.isAfter(dayjs(endDate).endOf('day'))) {
      return false;
    }
    if (order.order_status !== 'Completed' && order.order_status !== 'Delivered') {
      return false;
    }
    return true;
  });

  const filteredTasks = tasks.filter(task => {
    if (selectedRider && task.assigned_rider_id !== selectedRider.id) {
      return false;
    }
    const taskDate = dayjs(task.created_at);
    if (startDate && taskDate.isBefore(dayjs(startDate).startOf('day'))) {
      return false;
    }
    if (endDate && taskDate.isAfter(dayjs(endDate).endOf('day'))) {
      return false;
    }
    if (task.status !== 'completed') {
      return false;
    }
    return true;
  });

  const riderEarnings = filteredRiders.map(rider => {
    const riderOrders = filteredOrders.filter(order => order.asigned_delivery_rider === rider.id);
    const riderTasks = filteredTasks.filter(task => task.assigned_rider_id === rider.id);
    
    const totalDeliveryCharges = riderOrders.reduce((sum, order) => sum + parseFloat(order.delivery_charge || 0), 0) +
                                 riderTasks.reduce((sum, task) => sum + parseFloat(task.delivery_charge || 0), 0);
    
    const totalEarnings = riderOrders.reduce(
      (sum, order) =>
        sum + calculateEarnings(parseFloat(order.delivery_charge || 0), parseFloat(settings.riderfees_persantage || 0), parseFloat(settings.riderfees_minimum || 0)),
      0
    ) + riderTasks.reduce(
      (sum, task) =>
        sum + calculateEarnings(parseFloat(task.delivery_charge || 0), parseFloat(settings.riderfees_persantage || 0), parseFloat(settings.riderfees_minimum || 0)),
      0
    );

    return { ...rider, totalDeliveryCharges, totalEarnings, riderOrders, riderTasks };
  });

  const selectedRiderEarnings = selectedRider ? riderEarnings.find(rider => rider.id === selectedRider.id) : null;

  const totalOrders = filteredOrders.length + filteredTasks.length;
  const totalEarnings = filteredOrders.reduce(
    (sum, order) =>
      sum + calculateEarnings(parseFloat(order.delivery_charge || 0), parseFloat(settings.riderfees_persantage || 0), parseFloat(settings.riderfees_minimum || 0)),
    0
  ) + filteredTasks.reduce(
    (sum, task) =>
      sum + calculateEarnings(parseFloat(task.delivery_charge || 0), parseFloat(settings.riderfees_persantage || 0), parseFloat(settings.riderfees_minimum || 0)),
    0
  );

  const handleSettingsChange = (event) => {
    const { name, value } = event.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleSettingsSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/fetch_settings.php', {
        params: {
          riderfees_persantage: settings.riderfees_persantage,
          riderfees_minimum: settings.riderfees_minimum
        }
      });
      if (response.data.success) {
        toast.success('Settings updated successfully!');
      } else {
        toast.error('Failed to update settings');
      }
      setLoading(false);
    } catch (error) {
      toast.error('Failed to update settings');
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!settings || riders.length === 0 || (orders.length === 0 && tasks.length === 0)) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">No data available</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Typography variant="h4" gutterBottom>Rider Salary Management</Typography>
      <Card sx={{ mb: 3, padding: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Settings</Typography>
          <form onSubmit={handleSettingsSubmit}>
            <TextField
              label="Rider Fees Percentage"
              type="number"
              name="riderfees_persantage"
              value={settings.riderfees_persantage}
              onChange={handleSettingsChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Rider Fees Minimum"
              type="number"
              name="riderfees_minimum"
              value={settings.riderfees_minimum}
              onChange={handleSettingsChange}
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>Update Settings</Button>
          </form>
        </CardContent>
      </Card>
      {!selectedRider && (
        <Box sx={{ mb: 3 }}>
          <TextField
            label="Search Rider"
            variant="outlined"
            value={searchQuery}
            onChange={handleSearch}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
      <Box sx={{ mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(date) => setStartDate(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(date) => setEndDate(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="contained" color="primary" fullWidth onClick={() => setSelectedRider(null)}>Back</Button>
          </Grid>
        </Grid>
      </Box>
      <Card sx={{ mb: 3, padding: 2 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Summary</Typography>
          <Typography variant="body1"><strong>Total Orders:</strong> {totalOrders}</Typography>
          <Typography variant="body1"><strong>Total Earnings:</strong> {totalEarnings.toFixed(2)} LKR</Typography>
        </CardContent>
      </Card>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Rider Name</TableCell>
              {selectedRider && (
                <>
                  <TableCell>Delivery Charge</TableCell>
                  <TableCell>Percentage Amount</TableCell>
                  <TableCell>Earnings</TableCell>
                  <TableCell>Date & Time</TableCell>
                  <TableCell>Type</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedRider ? (
              <>
                {selectedRiderEarnings.riderOrders.map(order => (
                  <TableRow key={order.id}>
                    <TableCell>Rider: {order.id}</TableCell>
                    <TableCell>{selectedRider.name}</TableCell>
                    <TableCell>{parseFloat(order.delivery_charge || 0).toFixed(2)} LKR</TableCell>
                    <TableCell>{((parseFloat(order.delivery_charge || 0) * parseFloat(settings.riderfees_persantage || 0)) / 100).toFixed(2)} LKR</TableCell>
                    <TableCell>{calculateEarnings(parseFloat(order.delivery_charge || 0), parseFloat(settings.riderfees_persantage || 0), parseFloat(settings.riderfees_minimum || 0)).toFixed(2)} LKR</TableCell>
                    <TableCell>{dayjs(order.created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell><Chip label="Delivery" color="primary" /></TableCell>
                  </TableRow>
                ))}
                {selectedRiderEarnings.riderTasks.map(task => (
                  <TableRow key={task.id}>
                    <TableCell>Task: {task.id}</TableCell>
                    <TableCell>{selectedRider.name}</TableCell>
                    <TableCell>{parseFloat(task.delivery_charge || 0).toFixed(2)} LKR</TableCell>
                    <TableCell>{((parseFloat(task.delivery_charge || 0) * parseFloat(settings.riderfees_persantage || 0)) / 100).toFixed(2)} LKR</TableCell>
                    <TableCell>{calculateEarnings(parseFloat(task.delivery_charge || 0), parseFloat(settings.riderfees_persantage || 0), parseFloat(settings.riderfees_minimum || 0)).toFixed(2)} LKR</TableCell>
                    <TableCell>{dayjs(task.created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell><Chip label="Task" color="secondary" /></TableCell>
                  </TableRow>
                ))}
              </>
            ) : (
              filteredRiders.map(rider => (
                <TableRow key={rider.id} onClick={() => handleRiderClick(rider)} style={{ cursor: 'pointer' }}>
                  <TableCell>{rider.id}</TableCell>
                  <TableCell>{rider.name}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SalaryManagement;
