import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  InputAdornment,
  CircularProgress,
  Typography,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';

import newOrderSound from './success.mp3'; // Add the sound file to your project

dayjs.extend(relativeTime);

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [riders, setRiders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  const [pastOrders, setPastOrders] = useState([]);
  const [pastOrdersOpen, setPastOrdersOpen] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [newLocation, setNewLocation] = useState({ lat: null, lng: null });
  const [paymentMethod, setPaymentMethod] = useState('');
  const [editOrderModalOpen, setEditOrderModalOpen] = useState(false);
  const [foods, setFoods] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
    const interval = setInterval(checkForNewOrders, 30000);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchOrders(), fetchRiders()]);
    } catch (error) {
      toast.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/ordermanagement.php?action=fetch_orders');
      setOrders(response.data);
    } catch (error) {
      toast.error('Failed to fetch orders');
      throw error;
    }
  };

  const fetchRiders = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/ordermanagement.php?action=fetch_riders');
      setRiders(response.data);
    } catch (error) {
      toast.error('Failed to fetch riders');
      throw error;
    }
  };

  const checkForNewOrders = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/ordermanagement.php?action=fetch_orders');
      if (response.data.length > orders.length) {
        notifyNewOrder();
        // setOrders(response.data);
      }
    } catch (error) {
      console.error('Failed to check for new orders', error);
    }
  };

  const notifyNewOrder = () => {
    const audio = new Audio(newOrderSound);
    audio.play();
  };

  const handleOrderSelect = async (order) => {
    setLoading(true);
    try {
      const updatedOrder = {
        ...order,
        cart_items: JSON.parse(order.cart_items),
      };

      const cartItemsWithCategory = await Promise.all(
        updatedOrder.cart_items.map(async (item) => {
          const categoryResponse = await axios.get(`https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_category&food_id=${item.foodId}`);
          item.category = categoryResponse.data.title;
          return item;
        })
      );

      updatedOrder.cart_items = cartItemsWithCategory;
      setSelectedOrder(updatedOrder);
      setPaymentMethod(updatedOrder.payment_method);
      fetchPastOrders(order.user_id);
    } catch (error) {
      toast.error('Failed to fetch order details');
    } finally {
      setLoading(false);
    }
  };

  const fetchPastOrders = async (userId) => {
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/ordermanagement.php?action=fetch_past_orders&user_id=${userId}`);
      setPastOrders(response.data);
    } catch (error) {
      toast.error('Failed to fetch past orders');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedOrder({ ...selectedOrder, [name]: value });
  };

  const handleStatusChange = (status) => {
    setSelectedOrder({ ...selectedOrder, order_status: status });
  };

  const handleRiderAssign = (riderId) => {
    setSelectedOrder({ ...selectedOrder, asigned_delivery_rider: riderId });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const orderToSave = {
        ...selectedOrder,
        payment_method: paymentMethod,
      };
      await axios.post('https://kirupam.shop/admin/admin_api/ordermanagement.php?action=update_order', orderToSave);
      fetchOrders();
      toast.success('Order details updated successfully!');
    } catch (error) {
      toast.error('Failed to update order');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
  };

  const handleLocationModalOpen = () => {
    setNewLocation({
      lat: selectedOrder.delivery_location_lat,
      lng: selectedOrder.delivery_location_lng,
    });
    setLocationModalOpen(true);
  };

  const handleLocationModalClose = () => {
    setLocationModalOpen(false);
  };

  const handleSaveLocation = () => {
    setSelectedOrder({
      ...selectedOrder,
      delivery_location_lat: newLocation.lat,
      delivery_location_lng: newLocation.lng,
    });
    setLocationModalOpen(false);
    toast.info('Please change delivery charge.');
  };

  const handleEditOrderClick = async (orderId) => {
    await fetchFoods(selectedOrder.store_id);
    setEditOrderModalOpen(true);
  };

  const handleEditOrderModalClose = () => {
    setEditOrderModalOpen(false);
  };

  const fetchFoods = async (storeId) => {
    try {
      const response = await axios.get(`https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_foods&store_id=${storeId}`);
      const foodsWithDetails = await Promise.all(
        response.data.map(async (food) => {
          const optionsResponse = await axios.get(`https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_options&food_id=${food.id}`);
          const sizesResponse = await axios.get(`https://kirupam.shop/admin/admin_api/menu_management.php?action=fetch_sizes&food_id=${food.id}`);
          return {
            ...food,
            customOptions: optionsResponse.data || [],
            sizes: sizesResponse.data || [],
          };
        })
      );
      setFoods(foodsWithDetails);
    } catch (error) {
      toast.error('Failed to fetch foods');
    }
  };

  const handleAddToOrder = (food) => {
    const selectedSize = food.sizes.find((size) => size.id === food.selectedSize);
    const selectedOptions = food.selectedOptions || [];
    const optionsTotalPrice = selectedOptions.reduce((sum, option) => sum + option.option_price, 0);
    const totalPrice = food.base_price + selectedSize.size_price + optionsTotalPrice;

    const newItem = {
      food: food.title,
      category: food.category,
      selectedSizes: [{ size: selectedSize.size, quantity: 1 }],
      selectedCustomOptions: { [selectedSize.size]: selectedOptions.map((option) => option.custom_option) },
      totalPrice,
    };

    setSelectedOrder((prevOrder) => ({
      ...prevOrder,
      cart_items: [...prevOrder.cart_items, newItem],
      totalCharge: (parseFloat(prevOrder.totalCharge) + totalPrice).toFixed(2),
    }));

    toast.success('Item added to order');
  };

  const renderMap = () => {
    if (!selectedOrder) return null;
    const { delivery_location_lat, delivery_location_lng, store_location_lat, store_location_lng } = selectedOrder;
    const googleMapsUrl = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyBDa19FKk-mRQVgIzaHKA7-iXGbDeI3qv0&origin=${store_location_lat},${store_location_lng}&destination=${delivery_location_lat},${delivery_location_lng}`;
    return (
      <iframe
        title="map"
        width="100%"
        height="600"
        frameBorder="0"
        style={{ border: 2 }}
        src={googleMapsUrl}
        allowFullScreen
        zoom="5"
      ></iframe>
    );
  };

  const filteredOrders = Array.isArray(orders) ? orders.filter((order) =>
    (order.store_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.user_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.user_phone.includes(searchQuery) ||
      order.payment_method.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.order_status.toLowerCase().includes(searchQuery.toLowerCase())) &&
    (!filterStatus || order.order_status === filterStatus)
  ) : [];

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Pending':
        return '#fffde8';
      case 'Processing':
        return '#e3f9ff';
      case 'Completed':
        return '#e3ffea';
      case 'Cancelled':
        return '#ffe3e8';
      case 'Picked':
        return '#ffba7a';
      case 'Delivered':
        return '#ead9ff';
      case 'asigned':
        return '#c7cfff';
      case 'ready':
        return '#f5ffe8';
      default:
        return '#ffffff';
    }
  };

  const formatTimeRemaining = (time) => {
    const now = dayjs();
    return now.isAfter(time) ? `${now.to(time)}` : `in ${now.to(time)}`;
  };

  const handlePastOrdersOpen = () => {
    setPastOrdersOpen(true);
  };

  const handlePastOrdersClose = () => {
    setPastOrdersOpen(false);
  };

  const handleEditPaymentMethod = (orderId) => {
    const url = `https://ma.kirupam.com/edit_pay_m.php?order_id=${orderId}`;
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ToastContainer />
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <TextField
          label="Search Orders"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl fullWidth>
          <InputLabel id="filter-status-label">Filter by Status</InputLabel>
          <Select
            labelId="filter-status-label"
            value={filterStatus}
            onChange={handleFilterStatusChange}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Processing">Processing</MenuItem>
            <MenuItem value="ready">Ready</MenuItem>
            <MenuItem value="asigned">Assigned</MenuItem>
            <MenuItem value="Picked">Picked</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>Orders</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow sx={{ backgroundColor: 'tomato' }}>
                    <TableCell>ID</TableCell>
                    <TableCell>Restaurant</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell>Scheduled</TableCell>
                    <TableCell>Ordered</TableCell>
                    <TableCell>Payment</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentOrders.map((order) => (
                    <TableRow
                      key={order.id}
                      hover
                      onClick={() => handleOrderSelect(order)}
                      selected={selectedOrder?.id === order.id}
                      sx={{
                        cursor: 'pointer',
                        backgroundColor: selectedOrder?.id === order.id ? '#e0f7fa' : getStatusColor(order.order_status),
                        border: selectedOrder?.id === order.id ? '2px solid #00796b' : 'none',
                        fontWeight: selectedOrder?.id === order.id ? 'bold' : 'normal',
                      }}
                    >
                      <TableCell>{order.id}</TableCell>
                      <TableCell>{order.store_name} <br /> {order.store_phone} </TableCell>
                      <TableCell>{order.user_name}</TableCell>
                      <TableCell>{order.selected_date}</TableCell>
                      <TableCell>{order.created_at}</TableCell>
                      <TableCell>
                        {order.payment_method}
                        <IconButton onClick={() => handleEditPaymentMethod(order.id)} size="small" color="primary" sx={{ ml: 1 }}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>{order.order_status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={Math.ceil(filteredOrders.length / ordersPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              sx={{ mt: 2 }}
            />
          </Box>
          {selectedOrder && (
            <Box sx={{ flex: 2 }}>
              <Typography variant="h5" sx={{ mb: 2 }}>Order Details</Typography>
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Delivery Charge"
                      name="delivery_charge"
                      value={selectedOrder.delivery_charge}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={selectedOrder.order_status === 'Completed'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Delivery Location Latitude"
                      name="delivery_location_lat"
                      value={selectedOrder.delivery_location_lat}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={selectedOrder.order_status === 'Completed'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Delivery Location Longitude"
                      name="delivery_location_lng"
                      value={selectedOrder.delivery_location_lng}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={selectedOrder.order_status === 'Completed'}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h6" color="grayText" sx={{ mt: 2 }}><strong>Phone: {selectedOrder.user_phone}</strong></Typography>
                <br />
                {renderMap()}
                <a
                  href={`https://ma.kirupam.com/edit_order.php?order_id=${selectedOrder.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 2, ml: 2 }}
                    disabled={selectedOrder.order_status === 'Completed'}
                  >
                    Change Delivery Location
                  </Button>
                </a>
                <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 1, boxShadow: 2, mt: 2 }}>
                  <Typography variant="h6">Order Items</Typography>
                  {selectedOrder.cart_items.map((item, index) => (
                    <Box key={index} sx={{ borderBottom: '1px solid #e0e0e0', padding: 1 }}>
                      <Typography><strong>Food:</strong> {item.food}</Typography>
                      <Typography><strong>Category:</strong> {item.category}</Typography>
                      {item.selectedSizes.map((size, sizeIndex) => (
                        <Box key={sizeIndex}>
                          <Typography><strong>Size:</strong> {size.size} | <strong>Quantity:</strong> {size.quantity}</Typography>
                          {item.selectedCustomOptions && item.selectedCustomOptions[size.size] && (
                            <Typography><strong>Custom Options:</strong> {item.selectedCustomOptions[size.size].join(', ')}</Typography>
                          )}
                        </Box>
                      ))}
                      <Typography><strong>Total Price:</strong> {item.totalPrice}</Typography>
                    </Box>
                  ))}
                </Box>
                <TextField
                  label="Total Charge"
                  value={selectedOrder.totalCharge}
                  fullWidth
                  disabled
                  sx={{ my: 2 }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePastOrdersOpen}
                  sx={{ mt: 2 }}
                >
                  Show Previous Orders
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditOrderClick(selectedOrder.id)}
                  sx={{ mt: 2, ml: 2 }}
                  disabled={selectedOrder.order_status !== 'Pending'}
                >
                  Edit Order
                </Button>
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel id="status-label">Order Status</InputLabel>
                  <Select
                    labelId="status-label"
                    value={selectedOrder.order_status}
                    onChange={(e) => handleStatusChange(e.target.value)}
                    disabled={selectedOrder.order_status === 'Completed' || selectedOrder.order_status === 'Cancelled'}
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Processing">Processing</MenuItem>
                    <MenuItem value="ready">Ready</MenuItem>
                    <MenuItem value="asigned">Assigned</MenuItem>
                    <MenuItem value="Picked">Picked</MenuItem>
                    <MenuItem value="Delivered">Delivered</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
                {selectedOrder.order_status === 'PendingCardPayment' && (
                  <FormControl fullWidth sx={{ my: 2 }}>
                    <InputLabel id="payment-method-label">Payment Method</InputLabel>
                    <Select
                      labelId="payment-method-label"
                      value={paymentMethod}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      disabled={selectedOrder.order_status === 'Completed'}
                    >
                      <MenuItem value="Card">Card</MenuItem>
                      <MenuItem value="Cash">Cash</MenuItem>
                    </Select>
                  </FormControl>
                )}
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel id="rider-label">Assign Rider</InputLabel>
                  <Select
                    labelId="rider-label"
                    value={selectedOrder.asigned_delivery_rider}
                    onChange={(e) => handleRiderAssign(e.target.value)}
                    disabled={selectedOrder.order_status === 'Completed'}
                  >
                    {riders.map((rider) => (
                      <MenuItem key={rider.id} value={rider.id}>
                        {rider.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={selectedOrder.order_status === 'Delivered'}
                >
                  Save
                </Button>
              </>
            </Box>
          )}
        </Box>
      )}
      <Dialog open={pastOrdersOpen} onClose={handlePastOrdersClose}>
        <DialogTitle>Past Orders</DialogTitle>
        <DialogContent>
          {pastOrders.length ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Restaurant</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pastOrders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.id}</TableCell>
                    <TableCell>{order.store_name}</TableCell>
                    <TableCell>{dayjs(order.created_at).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell>{order.totalCharge}</TableCell>
                    <TableCell>{order.order_status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>No past orders found.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePastOrdersClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={locationModalOpen} onClose={handleLocationModalClose}>
        <DialogTitle>Change Delivery Location</DialogTitle>
        <DialogContent>
          <MapContainer
            center={[newLocation.lat, newLocation.lng]}
            zoom={13}
            style={{ height: '400px', width: '100%' }}
            whenCreated={(map) =>
              map.on('moveend', () => {
                const { lat, lng } = map.getCenter();
                setNewLocation({ lat, lng });
              })
            }
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={[newLocation.lat, newLocation.lng]}
              draggable
              eventHandlers={{
                dragend: (event) => {
                  const marker = event.target;
                  const position = marker.getLatLng();
                  setNewLocation(position);
                },
              }}
            />
          </MapContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLocationModalClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveLocation} color="primary">
            Save Location
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editOrderModalOpen}
        onClose={handleEditOrderModalClose}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>Edit Order</DialogTitle>
        <DialogContent>
          {foods.length ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Food</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Base Price</TableCell>
                    <TableCell>Sizes</TableCell>
                    <TableCell>Custom Options</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {foods.map((food) => (
                    <TableRow key={food.id}>
                      <TableCell>{food.id}</TableCell>
                      <TableCell>{food.title}</TableCell>
                      <TableCell>{food.category}</TableCell>
                      <TableCell>{food.base_price}</TableCell>
                      <TableCell>
                        {Array.isArray(food.sizes) && food.sizes.map((size) => (
                          <Chip
                            key={size.id}
                            label={`${size.size} - ${size.size_price}`}
                            onClick={() => {
                              const updatedFood = { ...food, selectedSize: size.id };
                              setFoods((prevFoods) =>
                                prevFoods.map((f) => (f.id === food.id ? updatedFood : f))
                              );
                            }}
                            sx={{ margin: 0.5, cursor: 'pointer' }}
                            color={food.selectedSize === size.id ? 'primary' : 'default'}
                          />
                        ))}
                      </TableCell>
                      <TableCell>
                        {Array.isArray(food.customOptions) && food.customOptions.map((option) => (
                          <Chip
                            key={option.id}
                            label={`${option.custom_option} - ${option.option_price}`}
                            onClick={() => {
                              const updatedFood = {
                                ...food,
                                selectedOptions: food.selectedOptions
                                  ? food.selectedOptions.includes(option)
                                    ? food.selectedOptions.filter((opt) => opt.id !== option.id)
                                    : [...food.selectedOptions, option]
                                  : [option],
                              };
                              setFoods((prevFoods) =>
                                prevFoods.map((f) => (f.id === food.id ? updatedFood : f))
                              );
                            }}
                            sx={{ margin: 0.5, cursor: 'pointer' }}
                            color={food.selectedOptions && food.selectedOptions.includes(option) ? 'primary' : 'default'}
                          />
                        ))}
                      </TableCell>
                      <TableCell>
                        <Button variant="contained" color="primary" onClick={() => handleAddToOrder(food)}>
                          Add to Order
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No foods found.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditOrderModalClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Orders;
