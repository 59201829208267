import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography
} from '@mui/material';
import { Delete, Edit, Search, Visibility, VisibilityOff } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Settings = () => {
  const [settings, setSettings] = useState({
    google_api: '',
    initial_delivery_fees: '',
    delivery_fees: '',
    max_delivery_fees: '',
    phone: ''
  });
  const [loading, setLoading] = useState(false);
  const [foodSliders, setFoodSliders] = useState([]);
  const [tab, setTab] = useState(0);
  const [editSlider, setEditSlider] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteTable, setDeleteTable] = useState(null);
  const [image, setImage] = useState(null);
  const [showGoogleApi, setShowGoogleApi] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchSettings();
    fetchFoodSliders();
  }, []);

  const fetchSettings = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/setting_management.php?table=settings_new');
      if (response.data.length > 0) {
        setSettings(response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
      toast.error('Error fetching settings');
    }
  };

  const fetchFoodSliders = async () => {
    try {
      const response = await axios.get('https://kirupam.shop/admin/admin_api/setting_management.php?table=food_slider');
      if (Array.isArray(response.data)) {
        setFoodSliders(response.data);
      }
    } catch (error) {
      console.error('Error fetching food sliders:', error);
      toast.error('Error fetching food sliders');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleSave = async () => {
    setLoading(true);
    const params = new URLSearchParams(settings).toString();
    try {
      await axios.get(`https://kirupam.shop/admin/admin_api/setting_management.php?update=settings_new&${params}`);
      toast.success('Settings updated successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      toast.error('Error saving settings');
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`https://kirupam.shop/admin/admin_api/setting_management.php?table=${deleteTable}&id=${deleteId}`);
      toast.success('Deleted successfully!');
      if (deleteTable === 'food_slider') fetchFoodSliders();
      setDeleteId(null);
      setDeleteTable(null);
    } catch (error) {
      console.error('Error deleting record:', error);
      toast.error('Error deleting record');
    }
  };

  const confirmDelete = (id, table) => {
    setDeleteId(id);
    setDeleteTable(table);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleEditSlider = (slider) => {
    setEditSlider(slider);
  };

  const handleSliderChange = (e) => {
    const { name, value } = e.target;
    setEditSlider({ ...editSlider, [name]: value });
  };

  const handleSaveSlider = async () => {
    const params = new URLSearchParams(editSlider).toString();
    try {
      await axios.get(`https://kirupam.shop/admin/admin_api/setting_management.php?update=food_slider&${params}`);
      toast.success('Food slider updated successfully!');
      fetchFoodSliders();
      setEditSlider(null);
    } catch (error) {
      console.error('Error saving slider:', error);
      toast.error('Error saving slider');
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleShowGoogleApi = () => {
    setPasswordDialogOpen(true);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    if (password === '1234') {
      setShowGoogleApi(true);
      setPasswordDialogOpen(false);
      setPassword('');
    } else {
      toast.error('Incorrect password');
    }
  };

  const handleAddSlider = () => {
    setEditSlider({ caption: '', image_url: '' });
  };

  const handleNewSliderImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSaveNewSlider = async () => {
    if (!editSlider.caption || !image) {
      toast.error('Please provide a caption and select an image.');
      return;
    }
    
    const formData = new FormData();
    formData.append('caption', editSlider.caption);
    formData.append('image', image);

    try {
      const response = await axios.post('https://kirupam.shop/admin/admin_api/add_slider.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Food slider added successfully!');
      fetchFoodSliders();
      setEditSlider(null);
      setImage(null);
    } catch (error) {
      console.error('Error adding new slider:', error);
      toast.error('Error adding new slider');
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <ToastContainer />
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Settings" />
        <Tab label="Food Sliders" />
      </Tabs>
      {tab === 0 && (
        <Box component="form" sx={{ flex: 1 }}>
          <h2>Settings</h2>
          <TextField
            label="Google API"
            name="google_api"
            type={showGoogleApi ? 'text' : 'password'}
            value={settings.google_api}
            onChange={handleInputChange}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowGoogleApi}>
                    {showGoogleApi ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Initial Delivery Fees"
            name="initial_delivery_fees"
            value={settings.initial_delivery_fees}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Delivery Fees"
            name="delivery_fees"
            value={settings.delivery_fees}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Max Delivery Fees"
            name="max_delivery_fees"
            value={settings.max_delivery_fees}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Phone"
            name="phone"
            value={settings.phone}
            onChange={handleInputChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSave} disabled={loading}>
            {loading ? 'Saving...' : 'Save Settings'}
          </Button>
        </Box>
      )}
      {tab === 1 && (
        <Box>
          <h2>Food Sliders</h2>
          <Button variant="contained" color="primary" onClick={handleAddSlider} sx={{ mb: 2 }}>
            Add New Slider
          </Button>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Image URL</TableCell>
                  <TableCell>Caption</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foodSliders.filter(slider => slider.caption.toLowerCase().includes(searchQuery.toLowerCase())).map((slider) => (
                  <TableRow key={slider.id}>
                    <TableCell>{slider.id}</TableCell>
                    <TableCell>{slider.image_url}</TableCell>
                    <TableCell>{slider.caption}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditSlider(slider)}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={() => confirmDelete(slider.id, 'food_slider')}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Dialog open={Boolean(editSlider)} onClose={() => setEditSlider(null)}>
        <DialogTitle>{editSlider?.id ? 'Edit Food Slider' : 'Add New Slider'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Caption"
            name="caption"
            value={editSlider?.caption || ''}
            onChange={handleSliderChange}
            fullWidth
            sx={{ mb: 2 }}
          />
          <TextField
            label="Image URL"
            name="image_url"
            value={editSlider?.image_url || ''}
            onChange={handleSliderChange}
            fullWidth
            sx={{ mb: 2 }}
            disabled={Boolean(editSlider?.id)}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={editSlider?.id ? handleImageChange : handleNewSliderImageChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              Upload Image
            </Button>
          </label>
          {editSlider?.image_url && (
            <img src={editSlider.id ? `https://kirupam.shop/admin/${editSlider.image_url}` : editSlider.image_url} alt="Slider" style={{ width: '100%', marginTop: '10px' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditSlider(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={editSlider?.id ? handleSaveSlider : handleSaveNewSlider} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={Boolean(deleteId)}
        onClose={() => setDeleteId(null)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this record?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteId(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
      >
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPasswordDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Settings;
