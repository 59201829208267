import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import '../index.css';

const Navbar = ({ handleDrawerToggle, onLogout }) => {
  const logoUrl = 'logo.png'; // Adjust the path to your logo

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <img src={logoUrl} alt="Logo" style={{ width: 40, height: 40, marginRight: 16 }} />
        <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
          Food Order Admin Panel
        </Typography>
        <Button color="inherit" onClick={onLogout} startIcon={<ExitToAppIcon />}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
