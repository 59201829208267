import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import StoreIcon from '@mui/icons-material/Store';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AssignmentIcon from '@mui/icons-material/Assignment';

const drawerWidth = 240;

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const area = sessionStorage.getItem('area');

  const fullMenu = (
    <>
      <ListItem button component={Link} to="/dashboard">
        <ListItemIcon><DashboardIcon /></ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
      <ListItem button component={Link} to="/orders">
        <ListItemIcon><ReceiptIcon /></ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
      <ListItem button component={Link} to="/menu">
        <ListItemIcon><RestaurantMenuIcon /></ListItemIcon>
        <ListItemText primary="Menu" />
      </ListItem>
      <ListItem button component={Link} to="/store-management">
        <ListItemIcon><StoreIcon /></ListItemIcon>
        <ListItemText primary="Store Management" />
      </ListItem>
      <ListItem button component={Link} to="/user-management">
        <ListItemIcon><StoreIcon /></ListItemIcon>
        <ListItemText primary="User Management" />
      </ListItem>
      <ListItem button component={Link} to="/settings">
        <ListItemIcon><SettingsIcon /></ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
      <ListItem button component={Link} to="/offer-management">
        <ListItemIcon><LocalOfferIcon /></ListItemIcon>
        <ListItemText primary="Offer Management" />
      </ListItem>
      <ListItem button component={Link} to="/delivery-rider-management">
        <ListItemIcon><TwoWheelerIcon /></ListItemIcon>
        <ListItemText primary="Delivery Rider Management" />
      </ListItem>
      <ListItem button component={Link} to="/task-management">
        <ListItemIcon><AssignmentIcon /></ListItemIcon>
        <ListItemText primary="Task Management" />
      </ListItem>

      <ListItem button component={Link} to="/salary-management">
        <ListItemIcon><AssignmentIcon /></ListItemIcon>
        <ListItemText primary="~Salary Management" />
      </ListItem>
    </>
  );

  const limitedMenu = (
    <>
      <ListItem button component={Link} to="/menu">
        <ListItemIcon><RestaurantMenuIcon /></ListItemIcon>
        <ListItemText primary="Menu" />
      </ListItem>
      <ListItem button component={Link} to="/store-management">
        <ListItemIcon><StoreIcon /></ListItemIcon>
        <ListItemText primary="Store Management" />
      </ListItem>
      <ListItem button component={Link} to="/orders">
        <ListItemIcon><ReceiptIcon /></ListItemIcon>
        <ListItemText primary="Orders" />
      </ListItem>
      <ListItem button component={Link} to="/task-management">
        <ListItemIcon><AssignmentIcon /></ListItemIcon>
        <ListItemText primary="Task Management" />
      </ListItem>
    </>
  );

  return (
    <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <List sx={{ marginTop: '64px' }}>
          {area === 'all' ? fullMenu : limitedMenu}
          <ListItem button component={Link} to="/rider-tracking">
            <ListItemIcon><DirectionsBikeIcon /></ListItemIcon>
            <ListItemText primary="Rider Tracking" />
          </ListItem>
        </List>
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        <List sx={{ marginTop: '64px' }}>
          {area === 'all' ? fullMenu : limitedMenu}
          <ListItem button component={Link} to="/rider-tracking">
            <ListItemIcon><DirectionsBikeIcon /></ListItemIcon>
            <ListItemText primary="Rider Tracking" />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
